import React from 'react';
import { useState,useEffect,createContext  } from 'react'
import { Link, useNavigate,Navigate, useLocation  } from 'react-router-dom'
import { getAuth, createUserWithEmailAndPassword} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, addDoc } from "../firebase_setup/firebase";
import { collection, query, where, getDocs,doc,updateDoc } from "firebase/firestore"; 
import { Button, Card, Checkbox, Label, TextInput, Select    } from 'flowbite-react';
import HeaderBar from './components/Header';
import AlertWithIcon from "./components/alerts"
import ToastMSG from './components/Toast';
import { ToastErrMSG } from './components/Toast';


function AddActivityComponent(){
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    const auth = getAuth();
    const [calendars, setCalendars] = useState([])
    const [zones, setZones] = useState([])
    const [name, setName] = useState("");
    const [description,setDescription] = useState("");
    const [duration,setDuration] = useState();
    const [img, setImg] = useState("");
    const [paymentNeed, setPaymentNeed] = useState(false);
    const [price, setPrice] = useState();
    const [message, setMessage] = useState("");
    const [calendar,setCalendar]=useState([]);
    const [zone,setZone]= useState([])
    const [status, setStatus] = useState("")
    const navigate = useNavigate();

    const clearform = () => {
      setName("")
      setDescription("")
      setDuration("")
      setImg("")
      setPaymentNeed(false)
      setPrice("")
      document.getElementById("paymentNeed").checked = false;
    }
    const AddNewActivity = async () => {
      if (description == "" || duration == "" || img == "" || !name || price == undefined){
        setMessage("ERROR:Por favor, revise los datos introducidos");
        setStatus("failure")
      }else{
        let finalPrice = parseFloat(price).toFixed(2)
        try {
          await addDoc(collection(db, "Activities"), {
              Description: description,
              Duration:duration,
              IMG: img,
              Name: name,
              PaymentNeed: paymentNeed,
              Price: finalPrice,
              Blocked: false,
          }).then(async function(docRef){
            await addDoc(collection(db,"ActivitySchedule"),{
              Activity: docRef,
              Calendar: calendar,
              Zone: zone,
              EndDate: new Date(new Date().getFullYear(), 11, 31),
              StartDate:new Date(new Date().setHours(0, 0, 0, 0)),
            }).then(  
              clearform(),
              navigate('/main', { state: { message: "Actividad creada correctamente",err:"success" } })
            )
          })
      } catch (error) {
          console.log(error)
          setMessage("ERROR ha ocurrido un error creando la actividad")
          setStatus("failure")
          clearform()
      }
      }
  

    }
    const checkData= () =>{

      console.log(calendar);
      console.log(zone);
      console.log(name);
      console.log(description);
      console.log(duration);
      console.log(img);
      console.log(paymentNeed);
      console.log(price);

    }
    useEffect(() => {
      const fetchCalendars = async () => {
        const response = collection(db, "Calendars");
        const data = await getDocs(response)
        const calendarios = []
        data.docs.forEach(item => {
          var fData = item.data();
          fData.id = item.id;
          calendarios.push(fData);
          setCalendars(calendarios)
        })
        return calendars;
      }
      const fetchZones = async () => {
        const response = collection(db, "Zones");
        const data = await getDocs(response)
        const zonas = []
        data.docs.forEach(item => {
          var fData = item.data();
          fData.id = item.id;
          zonas.push(fData);
          setZones(zonas)
        })
        return zones;
      }
      fetchCalendars();
      fetchZones();
    }, []);
    if (calendars[0] && zones[0]) {
      return(
        <section>
        <div>
          <div>
            <Card className='mediumSize alignCenter'>
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Nueva actividad</h5>
              <form>
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="name2"
                    value="Nombre"
                  />
                </div>
                <TextInput
                  id="name2"
                  placeholder="Nombre de la Actividad"
                  value={name} 
                  onChange={(e) => setName(e.target.value)}
                  shadow
                  type="text"
                  className="formInputs"
                  required
                  contentEditable
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="description"
                    value="Descripción"
                  />
                </div>
                <TextInput
                  id="description"
                  placeholder="Descripción de la Actividad"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  shadow
                  required
                  className="formInputs"
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="duration"
                    value="Duración (Minutos)"
                  />
                </div>
                <TextInput
                  id="duration"
                  placeholder="Duración de la Actividad"
                  value={duration}
                  onChange={(e) => setDuration(parseInt(e.target.value.match(/\d+/),10))}
                  shadow
                  required
                  className="formInputs"
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="img"
                    value="Imagen"
                  />
                </div>
                <TextInput
                  id="img"
                  placeholder="Imagen de la Actividad"
                  value={img}
                  onChange={(e) => setImg(e.target.value)}
                  shadow
                  required
                  className="formInputs"
                />
              </div>
              <div
      className="max-w-md"
      id="select"
    >
      <div className="mb-2 block">
        <Label
          htmlFor="calendars"
          value="Calendario"
        />
      </div>
      <Select
        id="calendars"
        defaultValue="Calendario de la Actividad"
        required
        onChange={(e) => setCalendar(e.target.value)}
      >
      <option value="">
        Seleccione un Calendario
      </option>
      {calendars.map((calendar)=>(
        <option value={calendar.CalendarID}>
          {calendar.CalendarID}
        </option>
      ))
      }
      </Select>
      
    </div>
              <div
      className="max-w-md"
      id="select2"
    >
      <div className="mb-2 block">
        <Label
          htmlFor="zones"
          value="Zona"
        />
      </div>
      <Select
        id="zones"
        defaultValue="Zona de la Actividad"
        required
        onChange={(e) => setZone(doc(db, "Zones", e.target.value))}
      >
      <option value="">
        Seleccione una Zona
      </option>
      {zones.map((zone)=>(
        <option value={zone.id}>
          {zone.Name}
        </option>
      ))
      }
      </Select>
      
    </div>
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="price"
                    value="Precio"
                  />
                </div>
                <TextInput
                  id="price"
                  placeholder="Precio de la actividad"
                  value={price}
                  onChange={(e) => {
                    const amount = e.target.value

                        if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {
                          setPrice(amount)
                        }
                  }}
                  shadow
                  required
                  className="formInputs"
                />
                <div className="flex items-center gap-2">
                  <Checkbox id="paymentNeed" onChange={e => setPaymentNeed(e.target.checked)}/>
                    <Label htmlFor="paymentNeed">
                      Pago necesario
                    </Label>
                </div>
              </div>
    
              <Button
                className='formBtn marginTop'
                onClick={AddNewActivity}
              >
              Guardar actividad
              </Button>
            </form>
          </Card>
          <nav className='divToast'>
              <nav className='divToast' onClick={()=>{setStatus("")}}>
           
              {status == "success" ? <ToastMSG message={message}></ToastMSG> : null}
              </nav>
              <nav className='divToast' onClick={()=>{setStatus("")}}>
              {status == "failure" ? <ToastErrMSG message={message}></ToastErrMSG> : null}
          </nav>
          </nav>
        </div>
      </div>
    </section>
    )
    }
  }
  
        
    


export default function AddActivity() {
    if (sessionStorage.getItem("user")) {
      return (
        <>
          <HeaderBar></HeaderBar>
          <AddActivityComponent></AddActivityComponent>
        </>
      )
    } else {
      return (
        <Navigate replace to='/' />
      )
    }
}
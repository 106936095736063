import React from 'react';
import { useState, useEffect, createContext } from 'react'
import { Link, useNavigate, Navigate, useLocation } from 'react-router-dom'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, addDoc } from "../firebase_setup/firebase";
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import { Container, Row, Col } from 'react-grid-system';
import { Button, Card, Checkbox, Label, TextInput, Select, Datepicker, Dropdown } from 'flowbite-react';
import HeaderBar from './components/Header';
import AlertWithIcon from "./components/alerts"
import ToastMSG from './components/Toast';
import { ToastErrMSG } from './components/Toast';
import ButtonMui from '@mui/material/Button';


function AddCalendarComponent() {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  const auth = getAuth();
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [UnabledDays, setUnabledDays] = useState([]);
  const [UnabledSlots, setUnabledSlots] = useState([]);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [lunes, setLunes] = useState(false);
  const [martes, setMartes] = useState(false);
  const [miercoles, setMiercoles] = useState(false);
  const [jueves, setJueves] = useState(false);
  const [viernes, setViernes] = useState(false);
  const [sabado, setSabado] = useState(false);
  const [domingo, setDomingo] = useState(false);
  const [slotName, setSlotName] = useState("");
  const [festivityDate, setFestivityDate] = useState();
  const [festivityName, setFestivityName] = useState("");
  const [startHour, setStartHour] = useState();
  const [endHour, setEndHour] = useState();
  const [showFestivityForm, setShowFestivityForm] = useState(false)
  const [showSlotsForm, setSlotsForm] = useState(false)
  const dayMap = { "D": domingo, "L": lunes, "M": martes, "X": miercoles, "J": jueves, "V": viernes, "S": sabado }
  const navigate = useNavigate();

  const checkData = () => {
    console.log(name);
    console.log(endDate);
    console.log(startDate);
  }
  const clearform = () => {
    setName("")
    setStartDate(0)
    setEndDate(0)
    setFestivityName("")
  }
  const AddNewCalendar = async () => {
    var cpUnabledDays = [].concat(UnabledDays)
    cpUnabledDays = cpUnabledDays.filter(u => u.Title != "" && !u.Deleted)
    cpUnabledDays.forEach(u => {
      var date = new Date()
      date.setHours(0,0,0,0)
      if (u.Date == "")
        u.Date = date
      
      delete u.id
      delete u.Deleted
    })
    var cpUnabledSlots = [].concat(UnabledSlots)
    cpUnabledSlots = cpUnabledSlots.filter(u => u.Why != "" || u.StartTime != "" || u.EndTime != "" && !u.Deleted)
    cpUnabledSlots.forEach(u => {
      delete u.id
      delete u.Deleted
    })
    if (name == "" || startDate == null || endDate == null) {
      setMessage("ERROR: Por favor, revise los datos introducidos");
      setStatus("failure")
    } else if (lunes == false && martes == false && miercoles == false && jueves == false && viernes == false && sabado == false && domingo == false) {
      setMessage("ERROR: Debe indicar al menos un día hábil para el calendario");
      setStatus("failure")
    } else {
      const fechaFin = new Date()
      fechaFin.setHours(endDate, 0, 0)
      const fechaInicio = new Date()
      fechaInicio.setHours(startDate, 0, 0)
      try {
        await addDoc(collection(db, "Calendars"), {
          CalendarID: name,
          EndTime: fechaFin,
          StartTime: fechaInicio,
          UnabledDays: cpUnabledDays,
          UnabledSlots: cpUnabledSlots,
          WeekDays: dayMap,
        }).then(
          clearform(),
          navigate('/main', { state: { message: "Calendario creado correctamente",err:"success" } })
        )
      } catch (error) {
        console.log(error)
        setMessage("ERROR ha ocurrido un error creando el calendario")
        setStatus("failure")
        clearform()
      }
    }
  }
  const addFestivity = () => {
    const festivos = [].concat(UnabledDays);
    setShowFestivityForm(true);
    if (festivos.length == 0 || festivos.find(uD => uD.id == "" && !uD.Deleted) == undefined) {
      const festivoVacio = { "id": "", "Date": "", "Title": "", "Deleted": false }
      festivos.push(festivoVacio);
      setUnabledDays(festivos)
    }
  }
  const addBloquedSlot = () => {
    const huecosBloqueados = [].concat(UnabledSlots);
    if (huecosBloqueados.length == 0 || huecosBloqueados.find(uD => uD.StartTime == "" &&
      uD.EndTime == "" && uD.Why == "" &&
      !uD.Deleted) == undefined) {
      const hueco = { "EndTime": "", "StartTime": "", "Why": "", "Deleted": false };
      huecosBloqueados.push(hueco);
      setUnabledSlots(huecosBloqueados);
    }
    setSlotsForm(true)
  }

  const handleDelete = (id, indice) => {
    if (id == "UnabledDay") {
      let uD = [].concat(UnabledDays);

      uD[indice].Deleted = true
      setUnabledDays(uD)
    }
    if (id == "UnabledSlots") {
      let uS = [].concat(UnabledSlots);

      uS[indice].Deleted = true
      setUnabledSlots(uS)
    }

  }
  return (
    <>
      <section>
        <div>
          <div>
            {/* {message != "" ? <AlertWithIcon message={message}></AlertWithIcon> : null} */}
            <Card className='bigSize '>
              <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white ">Nuevo Calendario</h5>
              <form>
                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor="name2"
                      value="Nombre"
                    />
                  </div>
                  <TextInput
                    id="name2"
                    placeholder="Nombre del Calendario"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    shadow
                    type="text"
                    className="formInputs"
                    required
                    contentEditable
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor="startDate"
                      value="Hora de Inicio"
                    />
                  </div>
                  <TextInput
                    id="startDate"
                    placeholder="Hora de Inicio"
                    value={startDate}
                    onChange={(e) => setStartDate(parseInt(e.target.value.match(/\d+/), 10))}
                    shadow
                    type="text"
                    className="formInputs"
                    required
                    contentEditable
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor="endDate"
                      value="Hora de Finalizacion"
                    />
                  </div>
                  <TextInput
                    id="endDate"
                    placeholder="Hora de Finalizacion"
                    value={endDate}
                    onChange={(e) => setEndDate(parseInt(e.target.value.match(/\d+/), 10))}
                    shadow
                    type="text"
                    className="formInputs"
                    required
                    contentEditable
                  />
                </div>
                <div className="mb-2 block">
                  <h6 className="font-bold tracking-tight text-gray-900 dark:text-white formFontSize">Festivos ({UnabledDays.filter(uD => !uD.Deleted).length})</h6>
                </div>

                <div className='innerFormDiv smallMarginTop'>
                  {showFestivityForm == true ?
                    <Container data={UnabledDays}>
                      <Row>
                        {UnabledDays.map((UnabledDay, i) => (
                          <Col sm={12} id={"UnabledDay" + i} className={`marginBot smallMarginTop ${UnabledDay.Deleted ? "displayNone" : ""}`}>
                            <Card>
                              <div >
                                <div>
                                  <div className="mb-2 block">
                                    <Label
                                      htmlFor="festivityName"
                                      value="Nombre de la Festividad"
                                    />
                                  </div>
                                  <TextInput
                                    placeholder="Nombre del Festivo"
                                    value={UnabledDay.Title}
                                    onChange={(e) => {
                                      UnabledDay.Title = e.target.value
                                      UnabledDay.id = "UnabledDay" + i
                                      setFestivityName(e.target.value)

                                    }}
                                    shadow
                                    type="text"
                                    className="formInputs"
                                    required
                                    contentEditable
                                  />

                                </div>
                                <div className="mb-1 block">
                                  <Label
                                    htmlFor="endDate"
                                    value="Fecha"
                                  />
                                </div>
                                <Datepicker
                                  value={UnabledDay.Date != "" ? new Date(UnabledDay.Date).toLocaleDateString() : new Date().toLocaleDateString()}
                                  onSelectedDateChanged={(e) => {
                                    e.setHours(0,0,0)
                                    UnabledDay.Date = e
                                    UnabledDay.id = "UnabledDay" + i
                                    setFestivityName(e)
                                  }
                                  }                                

                                  weekStart={2}
                                  language='es-ES'></Datepicker>


                                <ButtonMui onClick={() => handleDelete("UnabledDay", i)} color="error">
                                  Cancelar
                                </ButtonMui>

                              </div>
                            </Card>

                          </Col>
                        ))}
                      </Row>
                    </Container>
                    : null}


                </div>
                <Button
                  className='formBtn'
                  onClick={addFestivity}>
                  Añadir Festivo
                </Button>
                <div className="mb-2 block">
                  <h6 className="text-1xl font-bold tracking-tight text-gray-900 dark:text-white formFontSize">Horas Bloqueadas ({UnabledSlots.filter(uS => !uS.Deleted).length})</h6>
                </div>

                <div className='innerFormDiv smallMarginTop'>
                  {showSlotsForm == true ?
                    <Container data={UnabledSlots}>
                      <Row>
                        {UnabledSlots.map((UnabledSlot, i) => (
                          <Col sm={12} className={`marginBot smallMarginTop ${UnabledSlot.Deleted ? "displayNone" : ""}`}>
                            <Card>
                              <div >
                                <div>
                                  <div className="mb-2 block">
                                    <Label
                                      htmlFor="festivityName"
                                      value="Motivo del Bloqueo"
                                    />
                                  </div>
                                  <TextInput
                                    id="name2"
                                    placeholder="Motivo del Bloqueo"
                                    onChange={(e) => {
                                      UnabledSlot.id = "UnabledSlot" + i
                                      UnabledSlot.Why = e.target.value
                                      setSlotName(e.target.value)
                                    }}
                                    shadow
                                    type="text"
                                    className="formInputs"
                                    required
                                    contentEditable
                                  />

                                </div>
                                <div className="mb-1 block">
                                  <Label
                                    htmlFor="endDate"
                                    value="Hora de Inicio"
                                  />
                                </div>
                                <TextInput
                                  id="name2"
                                  placeholder="Hora de Inicio"
                                  onChange={(e) => {
                                    var hour = parseInt(e.target.value.match(/\d+/), 10)
                                    var date = new Date()
                                    date.setHours(hour, 0, 0)
                                    UnabledSlot.id = "UnabledSlot" + i
                                    UnabledSlot.StartTime = date
                                    setStartHour(hour)
                                  }}
                                  shadow
                                  type="number"
                                  className="formInputs"
                                  required
                                  contentEditable
                                />
                              </div>
                              <div className="mb-1 block">
                                <Label
                                  htmlFor="endDate"
                                  value="Hora de Finalización"
                                />
                              </div>
                              <TextInput
                                id="name2"
                                placeholder="Hora de Finalización"                                
                                onChange={(e) => {
                                  var hour = parseInt(e.target.value.match(/\d+/), 10)
                                  var date = new Date()
                                  date.setHours(hour, 0, 0)
                                  UnabledSlot.id = "UnabledSlot" + i
                                  UnabledSlot.EndTime = date
                                  setEndHour(parseInt(hour))                                  
                                }}
                                shadow
                                type="number"
                                className="formInputs"
                                required
                                contentEditable
                              />
                              <ButtonMui onClick={() => handleDelete("UnabledSlots", i)} color="error">
                                Cancelar
                              </ButtonMui>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </Container>
                    : null}
                </div>
                <Button
                  className='formBtn'
                  onClick={addBloquedSlot}>
                  Añadir Horas Bloqueadas
                </Button>
                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor="days"
                      value="Días de la semana:"
                    />
                  </div>
                  <div id="days" className='daysDiv'>
                    <Checkbox id="L" onChange={e => setLunes(e.target.checked)} />
                    <Label htmlFor="L" className='smallMarginLabel'>
                      Lunes
                    </Label>

                    <Checkbox id="M" onChange={e => setMartes(e.target.checked)} />
                    <Label htmlFor="M" className='smallMarginLabel'>
                      Martes
                    </Label>

                    <Checkbox id="X" onChange={e => setMiercoles(e.target.checked)} />
                    <Label htmlFor="X" className='smallMarginLabel'>
                      Miercoles
                    </Label>

                    <Checkbox id="J" onChange={e => setJueves(e.target.checked)} />
                    <Label htmlFor="J" className='smallMarginLabel'>
                      Jueves
                    </Label>

                    <Checkbox id="V" onChange={e => setViernes(e.target.checked)} />
                    <Label htmlFor="V" className='smallMarginLabel'>
                      Viernes
                    </Label>

                    <Checkbox id="S" onChange={e => setSabado(e.target.checked)} />
                    <Label htmlFor="S" className='smallMarginLabel'>
                      Sabado
                    </Label>

                    <Checkbox id="D" onChange={e => setDomingo(e.target.checked)} />
                    <Label htmlFor="D" className='smallMarginLabel'>
                      Domingo
                    </Label>
                  </div>
                </div>

                <Button
                  className='formBtn marginTop'
                  onClick={AddNewCalendar}
                >
                  Guardar Calendario
                </Button>
              </form>
            </Card>

            <nav className='divToast'>
              <nav className='divToast' onClick={() => { setStatus("") }}>

                {status == "success" ? <ToastMSG message={message}></ToastMSG> : null}
              </nav>
              <nav className='divToast' onClick={() => { setStatus("") }}>
                {status == "failure" ? <ToastErrMSG message={message} count={1}></ToastErrMSG> : null}
              </nav>
            </nav>
          </div>
        </div>
      </section>
    </>
  )
}
export default function AddCalendar() {
  if (sessionStorage.getItem("user")) {
    return (
      <>
        <HeaderBar></HeaderBar>
        <AddCalendarComponent></AddCalendarComponent>
      </>
    )
  } else {
    return (
      <Navigate replace to='/' />
    )
  }
}
import React, { useState, useEffect, useRef } from "react";
import { Modal } from 'flowbite-react';
import sha256 from 'crypto-js/sha256';
import { getSignature } from "../../firebase_setup/firebase";


const formRef = React.createRef()

export default function PaymentPlatformForm(props) {
    const [form, setForm] = useState([]);
    const price = props.activity.Price * 100;
    const MerchantID = "243036852"
    const AcquirerBIN = "0000270488"
    const TerminalID = "00000003"
    const TipoMoneda = "978"
    const Exponente = "2"
    const Cifrado = "SHA2"
    const PagoSoportado = "SSL"
    const Idioma = "1"
    const NumOp = props.pendingReservationID;
    var URL_OK = window.location.origin + "/PaymentConfirmed"
    var URL_NOK = window.location.origin + "/PaymentDenied"
    

    // Simular un clic en el botón programáticamente

    useEffect(() => {
        const fetchData = async () => {
            const formData = new Map();
            var signature = "Sin firma"
            try{
                await getSignature(JSON.stringify({data:{ price: price, pendingReservationID: props.pendingReservationID }}))
                  .then((result) => {
                    // Read result of the Cloud Function.
                    /** @type {any} */
                    const data = result.data;
                    signature = data.signature;
                  })
                  .catch((error) => {
                    console.log(error)
                  });
                
            }catch(e){
                console.log(e)
            }

            if (signature) {
                formData.set("MerchantID", MerchantID);
                formData.set("AcquirerBIN", AcquirerBIN);
                formData.set("TerminalID", TerminalID);
                formData.set("Num_operacion", NumOp);
                formData.set("Importe", price);
                formData.set("TipoMoneda", TipoMoneda);
                formData.set("Exponente", Exponente);
                formData.set("Cifrado", Cifrado);
                formData.set("URL_OK", URL_OK);
                formData.set("URL_NOK", URL_NOK);
                formData.set("Firma", signature);
                formData.set("Pago_soportado", PagoSoportado);
                formData.set("Idioma", Idioma);
            }
            setForm(formData);
        };

        fetchData();
    }, []);

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

   

    if (form.size == 13) {
        delay(10).then(() => formRef.current.submit());
    }


    return (
        <>
            <body>
                <form id="paymentForm" ref={formRef} action="https://pgw.ceca.es/tpvweb/tpv/compra.action" method="POST"
                    encType="application/x-www-form-urlencoded" >
                    {form.size > 0 ?
                        <div>
                            
                            <input name="MerchantID" type="hidden" value={form.get("MerchantID")} />
                            <input name="AcquirerBIN" type="hidden" value={form.get("AcquirerBIN")} />
                            <input name="TerminalID" type="hidden" value={form.get("TerminalID")} />
                            <input name="Num_operacion" type="hidden" value={form.get("Num_operacion")} />
                            <input name="Importe" type="hidden" value={form.get("Importe")} />
                            <input name="TipoMoneda" type="hidden" value={form.get("TipoMoneda")} />
                            <input name="Exponente" type="hidden" value={form.get("Exponente")} />
                            <input name="Cifrado" type="hidden" value={form.get("Cifrado")} />
                            <input name="URL_OK" type="hidden" value={form.get("URL_OK")} />
                            <input name="URL_NOK" type="hidden" value={form.get("URL_NOK")} />
                            <input name="Firma" type="hidden" value={form.get("Firma")} />
                            <input name="Pago_soportado" type="hidden" value={form.get("Pago_soportado")} />
                            <input name="Idioma" type="hidden" value={form.get("Idioma")} />
                        </div>
                        : null}

                    {/* Estos campos son opcionales */}
                    {/* <input NAME="datos_acs_20" TYPE="hidden" VALUE="## datos_acs_20 ##"/>
                <input NAME="firma_acs_20" TYPE="hidden" VALUE="## firma_acs_20##"/> */}
                    <center>
                        <input id="btnSubmit" type="submit" value="Comprar" />
                    </center>
                </form>
            </body>
        </>
    )
}

import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Card, Checkbox, Label, TextInput, Alert } from 'flowbite-react';
import { Link } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth, db  } from "../../firebase_setup/firebase";
import { collection, query, where, getDocs, doc, getDoc, deleteDoc,Firestore,updateDoc  } from "firebase/firestore";
import Dialog from '@mui/material/Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Toast from './Toast';



export default function DeleteButton(props){
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
      };
      const handleClickOpen = () => {
        setOpen(true);
      };
      const handleDelete = async() => {
        handleClose()
        const docRef =(doc(db, "Reservations", props.id));
        
        deleteDoc(docRef).then(() => { return(
          props.changeDeleted(props.id)
        ) }).catch(error => { console.log(error); });
      };
      const handleDeleteActivity = async() => {
        handleClose()
        const docRef =(doc(db, "Activities", props.id));
        
        deleteDoc(docRef).then(() => { return(
          props.changeDeleted(props.id)
        ) }).catch(error => { console.log(error); });
      };
    return(
        <>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" className='alertDelete'>Eliminar Reserva</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    ¿Está seguro de que quiere cancelar la siguiente reserva? Esta acción es irreversible:
                </DialogContentText>
                <DialogContentText className='alertDeleteText'>
                  {props.activity} en {props.zone}
                </DialogContentText>
                <DialogContentText className='alertDeleteText'>
                  El día: {props.reservationDay}
                </DialogContentText>
                <DialogContentText className='alertDeleteText'>
                  Horas: {props.horaInicio}:{props.minutosInicio} - {props.HoraFin}:{props.minutosFin}
                </DialogContentText>
                <DialogContentText className='alertDeleteText'>
                  Precio: {props.price}€
                </DialogContentText>
            </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
          <Button onClick={handleDelete} color="error">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <button onClick={handleClickOpen} className="text-white bg-[#c41616] hover:bg-[#e31717]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2"> Cancelar reserva</button>
        </>
    )
}
export function ActivityDeleteBtn(props){
  const [open, setOpen] = React.useState(false);
  const handleClose = (e) => {
      e.stopPropagation();
      setOpen(false);
    };
    const handleClickOpen = (e) => {
      e.stopPropagation(); 
      setOpen(true);
    };
    const handleDelete = async(e) => {
      e.stopPropagation();
      handleClose(e)
      const docRef =(doc(db, "Activities", props.id));
      updateDoc(docRef,{
        Blocked:true
      }).then(
       () => { return(
          props.changeDeleted(props.id)
        ) }
      ).catch(error => { console.log(error); });
    };
  return(
      <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" className='alertDelete'>Eliminar actividad</DialogTitle>
          <DialogContent>
              <DialogContentText>
                  ¿Está seguro de que quiere eliminar la actividad?
              </DialogContentText>
          </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cerrar
        </Button>
        <Button onClick={handleDelete} color="error">
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
    <button onClick={handleClickOpen} className='deleteBtn'> 
    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 18 20">
      <path d="M17 4h-4V2a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v2H1a1 1 0 0 0 0 2h1v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6h1a1 1 0 1 0 0-2ZM7 2h4v2H7V2Zm1 14a1 1 0 1 1-2 0V8a1 1 0 0 1 2 0v8Zm4 0a1 1 0 0 1-2 0V8a1 1 0 0 1 2 0v8Z"/>
    </svg>
    </button>
      </>
  )
}
export function ResetPasswordDialog(props){
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const handleClose = (e) => {
      e.stopPropagation();
      setOpen(false);
    };
    const handleClickOpen = (e) => {
      e.stopPropagation(); 
      setOpen(true);
    };
    const sentEmail = (e) => {
      e.stopPropagation();
      props.getRecoverEmail(email);
      setOpen(false)
    }

  return(
      <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" className='alertDelete'>Recuperar contraseña</DialogTitle>
          <DialogContent>
              <DialogContentText>
                  Introduzca su dirección de correo electrónico, si existe alguna cuenta asociada a la dirección proporcionada,
                  se le enviará un correo con indicaciones para cambiar la contraseña.
              </DialogContentText>
              <TextInput
                id="email1"
                name="email"
                type="email"
                required
                placeholder="Dirección de correo electrónico"
                onChange={(e) => setEmail(e.target.value)}
                className="formInputs passwordRecMarginTop"
              />
          </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Cerrar
        </Button>
        <Button onClick={sentEmail} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
    <label className='smallText'>¿Contraseña olvidada? <Link onClick={handleClickOpen} className='LinkColors'>Recuperar contraseña</Link>.</label>
      </>
  )
}
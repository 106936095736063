'use client';

import { Alert } from 'flowbite-react';
import { HiInformationCircle } from 'react-icons/hi';

export default function AlertWithIcon(props) {
  return (
    <Alert
      // estados disponibles: info    failure     success     warning
      color={props.status}
      icon={HiInformationCircle}
      rounded
    >
      <span>
        <p>
          <span className="font-medium">
            {props.message}
          </span>
        </p>
      </span>
    </Alert>
  )
}



import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Card, Avatar, Dropdown } from 'flowbite-react';
import { useNavigate, Redirect, Route, Navigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth, db } from "../firebase_setup/firebase";
import { collection, query, where, getDocs, doc, getDoc, orderBy } from "firebase/firestore";
import HeaderBar from './components/Header';
import DeleteButton from './components/DeleteButton';
import 'react-toastify/dist/ReactToastify.css';
import ToastMSG from './components/Toast';
import { ToastAlertMSG } from './components/Toast';

const reservesRef = React.createRef();
var codes = React.createRef();
function DefaultCard(props) {
  const [err, setErr] = useState(false);
  const changeDeletedCard = (childData) => {
    //  console.log(childData);
    props.changeDeleted(childData)
  }
  const navigate = useNavigate();
  let precio = ""
  if (props.price == 0) {
    precio = "Gratis"
  } else {
    precio = props.price + "€"
  }
  // console.log(auth.currentUser.email);
  return (
    <>
      <Card
        horizontal
        imgSrc={props.img}
        className='reserveCard'

      >

        <nav onClick={() => {
          if (props.reservationDay.getTime() >= new Date().getTime()) {
            navigate("/details", { state: { activity: props.Activity, reserve: props.reserveSelected } }, { replace: true })
          } else {
            setErr(true);
          }
        }} className="onClicNav"
        >
          <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {props.activity} en {props.zone}
          </h5>
          <p className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {props.reservationDay.toLocaleDateString()}
          </p>
          <p className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Horas: {props.horaInicio}:{props.minutosInicio} - {props.HoraFin}:{props.minutosFin}
          </p>
          <p className="font-normal text-gray-700 dark:text-gray-400 ">
            <nav className='alignCenter'>
              Código de acceso: {props.code}
            </nav>
            <nav className="alignLeft">
              {precio}
            </nav>
          </p>
        </nav>
        {props.reservationDay.getTime() >= new Date().getTime() &&

          <nav className="alignLeft">
            {props.payed !== true &&
              <DeleteButton
                horaInicio={props.horaInicio}
                minutosInicio={props.minutosInicio}
                HoraFin={props.HoraFin}
                minutosFin={props.minutosFin}
                activity={props.activity}
                zone={props.zone}
                reservationDay={props.reservationDay.toLocaleDateString()}
                price={props.price}
                id={props.id}
                changeDeleted={changeDeletedCard}
              ></DeleteButton>
            }
          </nav>

        }
      </Card>

      {err == true &&
        <nav onClick={() => setErr(false)} >
          <ToastAlertMSG className='alertMSGindex' message={"No se pueden seleccionar reservas de una fecha anterior a hoy."} />
        </nav>
      }
    </>

  )
}
export function ReservesComponent() {
  const [reservations, setReservations] = useState([]);
  const [reserves, setReserves] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [data, setData] = useState();
  const [finalized, setFinalized] = useState(false);
  const reservas = [];
  const navigate = useNavigate();

  const fetchReserves = async () => {
    const response = collection(db, "Reservations");
    const q = query(response, where("UserMail", "==", sessionStorage.getItem("userMail")), orderBy("StartDay", "desc"))
    const data = await getDocs(q)
    let reservas = []
    reservas = data.docs.map(item => {
      let itemData = item.data()
      itemData.id = item.id
      return itemData;
    })
    var index = 0
    setFinalized(false)
    var indexAwait = 0;
    reservas.forEach(reserva => {
      let activity = doc(db, "Activities", reserva.Activity.id);
      let zone = doc(db, "Zones", reserva.Zone.id);
      getDoc(activity).then((actActu) => {
        getDoc(zone).then((zoneActu) => {

          if (actActu._document != null) {
            reserva.Activity = actActu.data()
            reserva.Activity.id = activity.id
          }

          if (zoneActu._document != null) {
            reserva.Zone = zoneActu.data()
            reserva.Zone.id = zone.id
          }
          indexAwait++
          if (indexAwait >= reservas.length) {
            reservesRef.current = reservas
            setReserves(reservas)
            setFinalized(true)
          }
        });
      });

      index++
    })

    return reserves;

  }
  const fetchAcCodes = async () => {
    setDeleted(true);
    let token= "9621572b64b07d9cf88755f2981a14ecb21e51d2df263f0100aef78d8e1f174686c9b9ce5429e282";
    let lockID
    // if (process.env.TOKEN != undefined) {
    //   token = process.env.TOKEN;
    // } else {
    //   token = "9621572b64b07d9cf88755f2981a14ecb21e51d2df263f0100aef78d8e1f174686c9b9ce5429e282";
    // }
    if (process.env.LOCKID != undefined) {
      lockID = process.env.LOCKID;
    } else {
      lockID = 18171075572;
    }
    fetch('https://api.nuki.io/smartlock/' + lockID + '/auth', {
      headers: {
        'accept': 'application/json',
        'authorization': 'Bearer ' + token,
      }
    }).then(async (response) => {
      const data = await response.json();
      codes.current = data;
      return data;
    }).catch((err) => {
      console.log(err);
    });

  }
  useEffect(() => {
    fetchReserves();
    fetchAcCodes();
  }, []);

  function getDateFromTimeStamp(seconds, nanoseconds) {
    return new Date((seconds * 1000) + nanoseconds / 1000000)
  }
  const changeDeleted = (childData) => {
    setDeleted(true);
    let token= "9621572b64b07d9cf88755f2981a14ecb21e51d2df263f0100aef78d8e1f174686c9b9ce5429e282";
    let lockID
    // if (process.env.TOKEN != undefined) {
    //   token = process.env.TOKEN;
    // } else {
    //   token = "9621572b64b07d9cf88755f2981a14ecb21e51d2df263f0100aef78d8e1f174686c9b9ce5429e282";
    // }
    if (process.env.LOCKID != undefined) {
      lockID = process.env.LOCKID;
    } else {
      lockID = 18171075572;
    }

    const reservesUpdated = []
    reserves.forEach(reservation => {
      if (reservation.id != childData) {
        reservesUpdated.push(reservation)
      } else {
        let codeDeletionId = codes.current.find(c => c.code == reservation.AccessCode).id;
        fetch('https://api.nuki.io/smartlock/' + lockID + '/auth/' + codeDeletionId, {
          method: 'DELETE',
          headers: {
            'accept': 'application/json',
            'authorization': 'Bearer ' + token
          }
        }).catch((err) => {
          console.log(err);
        });

      }
    })
    if (reservesUpdated.length != 0) {
      reservesRef.current = reservesUpdated
      setReserves(reservesUpdated);
    } else {
      reservesRef.current = []
      setReserves([])
    }

  }
  const compare = (a, b) => {
    return new Date((b.StartDay.seconds * 1000) + b.StartDay.nanoseconds / 1000000) - new Date((a.StartDay.seconds * 1000) + a.StartDay.nanoseconds / 1000000);
  }

  if (finalized == true) {
    return (
      <><Container data={reserves}>
        <h1 className='marginTop titleActivities avatarTitle'>
          Mis reservas
        </h1>
        <Row>
          {reserves.map((reserve) => (
            <Col sm={12} className='marginBot'>
              <DefaultCard
                img={reserve.Activity.IMG}
                activity={reserve.Activity.Name}
                zone={reserve.Zone.Name}
                reservationDay={new Date((reserve.StartDay.seconds * 1000) + reserve.StartDay.nanoseconds / 1000000)}
                price={reserve.Price}
                code={reserve.AccessCode}
                horaInicio={new Date((reserve.StartDay.seconds * 1000) + reserve.StartDay.nanoseconds / 1000000).getHours().toString().padStart(2, '0')}
                minutosInicio={new Date((reserve.StartDay.seconds * 1000) + reserve.StartDay.nanoseconds / 1000000).getMinutes().toString().padStart(2, '0')}
                HoraFin={new Date((reserve.EndDay.seconds * 1000) + reserve.EndDay.nanoseconds / 1000000).getHours().toString().padStart(2, '0')}
                minutosFin={new Date((reserve.EndDay.seconds * 1000) + reserve.EndDay.nanoseconds / 1000000).getMinutes().toString().padStart(2, '0')}
                Activity={reserve.Activity}
                id={reserve.id}
                changeDeleted={changeDeleted}
                reserveSelected={reserve}
                payed={reserve.Payed}
              >
              </DefaultCard>
            </Col>
          ))}
        </Row>
      </Container>

        {deleted &&
          <nav onClick={() => setDeleted(false)}>
            <ToastMSG message={"Reserva cancelada correctamente"} />
          </nav>
        }

      </>
    )
  } else {
    return (
      <Container>
        <h1 className='marginTop titleActivities avatarTitle'>
          Mis reservas
        </h1><Row>
          <Col sm={12} className='marginBot'>
            <h4>No hay ninguna reserva realizada</h4>
          </Col>

        </Row>
      </Container>

    )
  }



}
export default function MyReserves() {
  if (sessionStorage.getItem("user")) {
    return (
      <>
        <HeaderBar></HeaderBar>
        <ReservesComponent></ReservesComponent>
      </>


    )
  } else {
    return (
      <Navigate replace to='/' />
    )
  }

}
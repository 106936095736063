import React, { useEffect, useState, useCallback, createContext } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Card, Avatar, Dropdown, Button } from 'flowbite-react';
import { useNavigate, redirect, Route, Navigate, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth, db } from "../firebase_setup/firebase";
import { collection, query, where, getDocs, updateDoc, doc, getDoc} from "firebase/firestore";
import gato from '../img/gato.png'
import { ActivityDeleteBtn } from './components/DeleteButton';
import ToastMSG from './components/Toast';
import HeaderBar from './components/Header';


export default function PaymentDenied() {
    const pendingId = sessionStorage.getItem("pendingId");
    const [PendingReservation, setPendingReservation] = useState()
    const navigate = useNavigate();

    const updateReservation = async (reservation) => {
        let pendingActivityRef = doc(db, "PendingReservations", pendingId)
        await updateDoc(pendingActivityRef, {
            PendingPayment: false,
        })

    }

        updateReservation(PendingReservation);
        navigate('/main', { state: { message: "La operación de pago ha resultado errónea o se ha cancelado.", err: "failure" } })
    return (
        <Navigate to="/main" state={{message: "La operación de pago ha resultado errónea o se ha cancelado.", err: "failure" }} />
      )



}
import React from 'react';
import { useState } from 'react'
import { Link, useNavigate, Navigate } from 'react-router-dom'
import { getAuth, deleteUser, signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, addDoc } from "../firebase_setup/firebase";
import { collection, query, where, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { Button, Card, Checkbox, Label, TextInput, Datepicker } from 'flowbite-react';
import HeaderBar from './components/Header';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonMaterial from '@mui/material/Button';
import { ToastErrMSG } from './components/Toast'
import { errorMonitor } from 'events';

const currentUser = React.createRef()
const auth = React.createRef()
const errorMsg = React.createRef();
function ProfileUpdateComponent() {
  currentUser.current = JSON.parse(sessionStorage.getItem("currentUser"));
  auth.current = getAuth();
  var nameValue = ""
  var lastNameValue = ""
  var birthDateValue = new Date()
  if(currentUser.current){
    if(currentUser.current.Name)
    nameValue = currentUser.current.Name
    if(currentUser.current.LastName)
    lastNameValue = currentUser.current.LastName
    if(currentUser.current.BirthDate)
    birthDateValue = getDateFromTimeStamp(currentUser.current.BirthDate.seconds,currentUser.current.BirthDate.nanoseconds)
  }
 
  const [name, setName] = useState(nameValue);
  const [lastname, setLastName] = useState(lastNameValue)
  const [birthDate, setBirthDate] = useState(birthDateValue)
  const [open, setOpen] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState([]);
  const navigate = useNavigate();
 
  function getDateFromTimeStamp(seconds, nanoseconds) {
    return new Date((seconds * 1000) + nanoseconds / 1000000)
  }

  const handleDeleteDialogOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    const reservationSch = query(collection(db, 'Users'), where("Email", "==", currentUser.current.Email))
    var errors = []
    await getDocs(reservationSch).then(async (user) => {

      const docRef = (doc(db, "Users", user.docs[0].id));
      
      
      
      await deleteDoc(docRef).catch((error) => {
        errors.push("Error: No se pudo eliminar la información de usuario." + error)
      });

      await deleteUser(auth.current.currentUser).catch((error) => {
        errors.push("Error: Se ha producido un error al eliminar el usuario." + error)
      });

      handleClose()

      if (errors.length == 0) {
        signOut(auth.current).then(() => {
          sessionStorage.removeItem("user");
          sessionStorage.removeItem("userMail");
          sessionStorage.removeItem("currentUser");
          sessionStorage.removeItem("reserveDeletion");
          navigate("/", { state: { message: "Se ha eliminado el usuario correctamente." } })
        })
      } else {       
        setErrorMsg(errors)
      }
    })

  }

  const updateProfile = async () => {
    try {
      await addDoc(collection(db, "Users"), {
        Name: name,
        LastName: lastname,
        BirthDate: birthDate,
        Email: sessionStorage.getItem("userMail"),
      })
      navigate('/main', { replace: true });

    } catch (error) {
      console.log(error)
    }

  }
  const updateCurrentProfile = async () => {
    const currentUserActu = JSON.parse(sessionStorage.getItem("currentUser"));
    const userRef = doc(db, "Users", currentUserActu.id)
    let nombreAct = "";
    let lastnameAct = "";
    let birthDateAct;
    if (name == "") {
      nombreAct = currentUserActu.Name
    } else {
      nombreAct = name
    }
    if (lastname == "") {
      lastnameAct = currentUserActu.Lastname
    } else {
      lastnameAct = lastname
    }
    if (birthDate == null) {
      birthDateAct = currentUserActu.BirthDate
    } else {
      birthDateAct = birthDate
    }
    try {
      await updateDoc(userRef, {
        Name: nombreAct,
        LastName: lastnameAct,
        BirthDate: birthDateAct
      })
      navigate('/main', { replace: true });
    } catch (error) {
      console.log(error);
    }
  }
const deleteErr = (event) => {
  if(event != null){
    let removeElement = event.currentTarget.textContent
    let updatedList = [];
    if(errorMsg.length > 0){
      if(errorMsg.length > 1){          
        errorMsg.forEach(e => {
          if(e != removeElement){
            updatedList.push(e);
          }
        })     
      }
      setErrorMsg(updatedList)
    }
  } 
  }

  if (sessionStorage.getItem("currentUser")) {
    let count = 1;
    return (
      <section>
      
          {errorMsg.length > 0 ? errorMsg.map((err) => (
            <div onClick={deleteErr}>
              <ToastErrMSG message={err} count={count++}/>
            </div>
            )) : null
          }
          
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" className=''>¿Esta seguro de que desea eliminar su cuenta?</DialogTitle>
          <DialogContent>
            <DialogContentText className='contentAlign'>
              Esta acción es irreversible y eliminara toda la información relacionada con su cuenta incluyendo la misma.
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <ButtonMaterial onClick={handleClose} color="primary">
              Cancelar
            </ButtonMaterial>
            <ButtonMaterial onClick={handleDelete} color="error">
              Eliminar
            </ButtonMaterial>
          </DialogActions>
        </Dialog>
        <div>
          <div>
            <Card className='mediumSize alignCenter'>
              <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Actualiza tu perfil</h5>
              <form>
                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor="name2"
                      value="Tu nuevo nombre"
                    />
                  </div>
                  <TextInput
                    id="name2"
                    placeholder="Ej: Antonio"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    shadow
                    type="text"
                    className="formInputs"
                    contentEditable
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor="apellidos2"
                      value="Nuevos apellidos"
                    />
                  </div>
                  <TextInput
                    id="apellidos2"
                    placeholder="Ej: Rodriguez Gonzalez"
                    value={lastname}
                    onChange={(e) => setLastName(e.target.value)}
                    shadow
                    className="formInputs"
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor="birthdate2"
                      value="Nueva fecha de nacimiento"
                    />
                  </div>
                  <Datepicker defaultDate={birthDate} onSelectedDateChanged={(e) => setBirthDate(e)} weekStart={2} language='es-ES' />
                </div>
                <Button
                  className='formBtn marginTop'
                  onClick={updateCurrentProfile}
                >
                  Actualiza tu perfil
                </Button>
                <Button
                  className='formBtn marginTop deleteButton'
                  onClick={handleDeleteDialogOpen}
                >
                  Eliminar perfil
                </Button>

              </form>
            </Card>
          </div>
        </div>
      </section>
    )
  } else {
    return (
      <section>
        <div>
          <div>
            <Card className='mediumSize alignCenter'>
              <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Nuevo perfil</h5>
              <form>
                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor="name2"
                      value="Tu nombre"
                    />
                  </div>
                  <TextInput
                    id="name2"
                    placeholder="Ej: Antonio"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    shadow
                    type="text"
                    className="formInputs"
                    contentEditable
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor="apellidos2"
                      value="Apellidos"
                    />
                  </div>
                  <TextInput
                    id="apellidos2"
                    placeholder="Ej: Rodriguez Gonzalez"
                    value={lastname}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    shadow
                    className="formInputs"
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label
                      htmlFor="birthdate2"
                      value="Fecha de nacimiento"
                    />
                  </div>
                  <Datepicker onSelectedDateChanged={(e) => setBirthDate(e)} weekStart={2} language='es-ES' />
                </div>
                <Button
                  className='formBtn marginTop'
                  onClick={updateProfile}
                >
                  Crea tu perfil
                </Button>

              </form>
            </Card>
          </div>
        </div>
      </section>
    )
  }



}

export default function ProfileUpdate() {
  if (sessionStorage.getItem("user")) {
    return (
      <>
        <HeaderBar></HeaderBar>
        <ProfileUpdateComponent></ProfileUpdateComponent>
      </>
    )
  } else {
    return (
      <Navigate replace to='/' />
    )
  }
}
'use client';

import { Toast } from 'flowbite-react';
import { HiCheck, HiX, HiExclamation } from 'react-icons/hi';

export default function ToastMSG(props) {
  return (
    <Toast className='toastAlign'>
      <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200 ">
        <HiCheck className="h-5 w-5" />
      </div>
      <div className="ml-3 text-sm font-normal">
        {props.message}
      </div>
    </Toast>

  )
}
export function ToastErrMSG(props) {
  var count = props.count || 1
  return (
      <Toast style={{marginTop: count * 80}} className='toastAlign'>
        <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
          <HiX className="h-5 w-5" />
        </div>
        <div className="ml-3 text-sm font-normal">
          {props.message}
        </div>
      </Toast>
  )
}

export function ToastAlertMSG(props) {
  return (
    <Toast className='toastAlign'>
      <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
        <HiExclamation className="h-5 w-5" />
      </div>
      <div className="ml-3 text-sm font-normal">
        {props.message}
      </div>
    </Toast>
  )
}
import React, { useEffect, useState } from 'react';
import { Card, Label, TextInput } from 'flowbite-react';
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import { auth, signInWithEmailAndPassword, signInWithGoogle, provider, sendPasswordResetEmail } from "../firebase_setup/firebase";
import { getAuth, signInWithPopup, FacebookAuthProvider } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth"
import AlertWithIcon from "./components/alerts"
import ToastMSG from './components/Toast';
import { ToastErrMSG } from './components/Toast';
import { ResetPasswordDialog } from './components/DeleteButton';
import junta from '../img/pastilla verde.jpg'
import modeloII from '../img/cartel_modelo_II.jpg'

function HeaderComponent() {
  return (
    <>
      <div className='alignCenter alignSmallScreen'>
        <h1 className='titleDefault avatarTitle'>Reservas Villagonzalo</h1>
      </div>
    </>
  )
}
function CardWithFormInputs() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [errorMessage, setErrorMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [toastSuccess, setToastSuccess] = useState("");
  const location = useLocation();

  useEffect(() => {
    // console.log(location);

    if (location && location.state && location.state.message !== "") {
      setToastSuccess(location.state.message)
      setShowToast(true)
    }
  }, [])
  const navigate = useNavigate();

  if (auth.currentUser != null) {
    sessionStorage.setItem("user", user);
    sessionStorage.setItem("userMail", user.email);
    navigate("/main")
  }
  // declarar los handle dentro del componente o pasar contexto
  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        sessionStorage.setItem("user", user);
        sessionStorage.setItem("userMail", user.email);
        navigate("/main")
      })
      .catch((error) => {
        const errorCode = error.code;
        if (errorCode == "auth/invalid-email") {
          setErrorMessage("ERROR: Dirección de correo electrónico no válida. Por favor, revise la dirección de correo electrónico introducida")
        } else if (errorCode == "auth/missing-password") {
          setErrorMessage("ERROR: Contraseña no introducida. Por favor, introduzca su contraseña")
        } else if (errorCode == "auth/wrong-password") {
          setErrorMessage("ERROR: Contraseña no válida. Por favor, revise su contraseña")
        } else if (errorCode == "auth/user-not-found") {
          setErrorMessage("ERROR: No se ha encontrado un usuario con la dirección de correo electronico introducida")
        }
      });

  }
  const googleLogin = (e) => {
    signInWithGoogle()
  }
  const loginWithFacebook = (e) => {
    const auth = getAuth()
    signInWithPopup(auth, provider).then(({ result }) => {
      const user = result.user;

      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      const credential = FacebookAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
      sessionStorage.setItem("user", user);
      navigate("/main")
    })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);
        if (errorCode == "auth/account-exists-with-different-credential") {
          setShowErrorToast(true)
          setToastSuccess("ERROR: Ya existe esta cuenta con distintas credenciales, pruebe a iniciar sesión con otro proveedor")
        } else {
          setShowErrorToast(true)
          setToastSuccess("ERROR: Ha ocurrido un error inesperado por favor vuelva a intentarlo")
        }

      });
  }
  const sendPasswordReset = async (email) => {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      setShowToast(true)
      setToastSuccess("Se ha enviado el correo de recuperacion de contraseña al correo indicado correctamente");
    } catch (err) {
      console.error(err);
      if (err.code == 'auth/invalid-email') {
        setShowErrorToast(true)
        setToastSuccess("ERROR: La dirección de correo electrónico introducida no es válida, por favor introduzca una dirección de correo electrónico válida")
      } else if (err.code == 'auth/user-not-found') {
        setShowErrorToast(true)
        setToastSuccess("ERROR: No se encuentra ningun usuario asociado a la direccón de correo electrónico introducida")
      } else {
        setShowErrorToast(true)
        setToastSuccess("ERROR: Ha ocurrido un error inesperado por favor vuelva a intentarlo")
      }
    }
  }
  const closeToast = () => {
    setShowToast(false);
    setShowErrorToast(false);
  }
  const getRecoverEmail = (childData) => {
    // setEmailRecover(childData);
    sendPasswordReset(childData);
  }
  return (
    <>
      <Card className='mediumSize alignCenter'>
        {errorMessage != "" ? <AlertWithIcon message={errorMessage} status="failure"></AlertWithIcon> : null}
        <form className="flex flex-col gap-4 ">
          <div>
            <div className="mb-2 block">
              <Label
                htmlFor="email1"
                value="Correo electrónico"
              />
            </div>
            <TextInput
              id="email1"
              name="email"
              type="email"
              required
              placeholder="EJ: ejemplo@gmail.com"
              onChange={(e) => setEmail(e.target.value)}
              className="formInputs"
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label
                htmlFor="password1"
                value="Contraseña"
              />
            </div>
            <TextInput
              id="password1"
              name="password"
              type="password"
              required
              placeholder="Contraseña"
              onChange={(e) => setPassword(e.target.value)
              }
              className="formInputs"
            />
          </div>

          <button type="button" className="text-white bg-[#0E7490] hover:bg-[#0a5063]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2 formBtn formBtnNoImage" onClick={onLogin}>
            Iniciar sesión
          </button>
          <button type="button" className="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2 formBtn" onClick={googleLogin}>
            <svg className="w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 19">
              <path fillRule="evenodd" d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z" clipRule="evenodd" />
            </svg>
            Inicio de sesión con Google
          </button>
          <button type="button" className="formBtn marginTopnegative text-white bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 mr-2 mb-2" onClick={loginWithFacebook}>
            <svg className="w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
              <path fillRule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clipRule="evenodd" />
            </svg>
            Iniciar sesión con Facebook
          </button>

        </form>
        <label className='smallText'>¿No tienes una cuenta? <Link to="/signIn" className='LinkColors'>Regístrate ahora</Link>.</label>
        <ResetPasswordDialog getRecoverEmail={getRecoverEmail}></ResetPasswordDialog>
      </Card>
      <nav className='divToast' onClick={closeToast}>
        {showToast ? <ToastMSG message={toastSuccess}></ToastMSG> : null}
        {showErrorToast ? <ToastErrMSG message={toastSuccess}></ToastErrMSG> : null}
      </nav>
    </>
  )
}


export default function Login() {
  if (sessionStorage.getItem("user") != null) {
    return (
      <Navigate to="/main" />
    )
  } else {
    return (
      <>
        <HeaderComponent></HeaderComponent>
        <div>
          <div>
            <CardWithFormInputs></CardWithFormInputs>
          </div>
          <div className='divAbout'>
            <img src={modeloII} alt="junta de extremadura"></img>
          </div>
        </div>
      </>
    )
  }
}
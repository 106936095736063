
import React from 'react';
import HeaderBar from './components/Header';
import {useNavigate  } from 'react-router-dom'
import modeloII from '../img/cartel_modelo_II.jpg'

function AboutComponent(){
   return(
    <>
        <img src={modeloII} alt="junta de extremadura" className="imgPanelAbout"></img>

    </>
   )
    }

export default function About() {
    const navigate = useNavigate();

    function BacktoLogin() {
        navigate('/', { replace: true });
    }

    if (sessionStorage.getItem("user")) {
      return (
        <>
          <HeaderBar></HeaderBar>
          <AboutComponent></AboutComponent>
        </>
      )
    } else {
      return (
        <>
        <h1 className='titleDefault avatarTitle cursorPointerHead' onClick={BacktoLogin}>Reservas Villagonzalo</h1>
        <AboutComponent></AboutComponent>
      </>
        
      )
    }
}
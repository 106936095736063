import React, { useEffect, useState, useCallback, createContext } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Card, Avatar, Dropdown, Button } from 'flowbite-react';
import { useNavigate, redirect, Route, Navigate, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth, db } from "../firebase_setup/firebase";
import { collection, query, where, getDocs, addDoc, updateDoc, doc } from "firebase/firestore";
import gato from '../img/gato.png'
import { ActivityDeleteBtn } from './components/DeleteButton';
import ToastMSG from './components/Toast';
import HeaderBar from './components/Header';


export default function PaymentConfirmed() {
        return (
            <Navigate to="/main" state={{message: "El pago se ha realizado correctamente", err: "success" }} />
          )
}
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import MainPage from "../pages/MainPage"
import { GoogleAuthProvider, getAuth, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut, FacebookAuthProvider } from "firebase/auth"
import { getFirestore, query, getDocs, collection, where, addDoc } from "firebase/firestore"
import { BrowserRouter, Routes, Route, Link, Navigate, useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyBk2yn1sMlSjBO2zWHTm3jFsdxhJ5xn4nQ",
  authDomain: "villagonzalo-60f9e.firebaseapp.com",
  projectId: "villagonzalo-60f9e",
  storageBucket: "villagonzalo-60f9e.appspot.com",
  messagingSenderId: "713215452516",
  appId: "1:713215452516:web:a05c8d06729e9592378671",
  measurementId: "G-E8ZT0RKF9V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const functions = getFunctions(app,"europe-west1");
const getSignature = httpsCallable(functions, 'getSignature');

const signInWithGoogle = async () => {
  let user;
  signInWithPopup(auth, googleProvider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      // IdP data available using getAdditionalUserInfo(result)
      // ...
      console.log(user);
      sessionStorage.setItem("user", user);
      sessionStorage.setItem("userMail", user.email);
      //// console.log(sessionStorage.getItem("user"));
      window.location.replace("/main");
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
};
const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};
const registerWithEmailAndPassword = async (name, email, password, lastname, birthDate) => {

  await createUserWithEmailAndPassword(auth, email, password).then(async () =>
    await addDoc(collection(db, "Users"), {
      Name: name,
      LastName: lastname,
      BirthDate: birthDate,
      Email: email,
      Admin: false
    }).catch(err => {
      console.log(err.code)
    })

  )


  // const user = res.user;



};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logout = () => {
  signOut(auth);
};
const provider = new FacebookAuthProvider()
export {
  app,
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  signInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordResetEmail,
  logout,
  addDoc,
  query,
  provider,
  getSignature};
import React from 'react';
import { useState, useEffect } from 'react'
import { Link, useNavigate  } from 'react-router-dom'
import { getAuth, createUserWithEmailAndPassword, signInAnonymously, signOut} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, addDoc, registerWithEmailAndPassword } from "../firebase_setup/firebase";
import { collection, query, where, getDocs } from "firebase/firestore"; 
import { Button, Card, Checkbox, Label, TextInput,Datepicker} from 'flowbite-react';
import AlertWithIcon from "./components/alerts"
import ToastMSG from './components/Toast';
import { ToastErrMSG } from './components/Toast';

function HeaderComponent() {

  const navigate = useNavigate();

  // declarar los handle dentro del componente o pasar contexto
  function BacktoLogin() {
    navigate('/', { replace: true });
    //<>
    //    <Route path="/main"></Route>
    //</>
  }
  return (
    <>
      <h1 className='titleDefault avatarTitle cursorPointerHead' onClick={BacktoLogin}>Reservas Villagonzalo</h1>
    </>
  )
}
export default function Register() {
  const navigate = useNavigate();
  const sleep = ms => new Promise(r => setTimeout(r, ms));
  const auth = getAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [name, setName] = useState("");
  const [lastname,setLastname] = useState("")
  const [birthDate,setBirthdate] = useState(new Date())
  const [user, loading, error] = useAuthState(auth);
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState("")
  let estado = "";
  
  const register = async () => {
    let error = false;
      
    if (name == "" || lastname == "" || email == "" || birthDate == null || password == "" || passwordRepeat== ""){
      setErrorMessage("ERROR: Por favor, revise los datos introducidos");
      estado = "failure";
      // console.log(estado)
      error = true;
    } else if( password != passwordRepeat){
      setErrorMessage("ERROR: Las contraseñas no coinciden");
      estado = "failure";
      error = true;
    }
    if(!error){
      try{
        await registerWithEmailAndPassword(name, email, password,lastname,birthDate);
        // setErrorMessage("Cuenta creada correctamente");
        estado = "success";
        navigate('/',{ state: { message: "Cuenta creada correctamente" } })
      }catch(error){
        console.log(error.code);
        if(error.code == "auth/email-already-in-use"){
          setErrorMessage("ERROR: La dirección de correo electrónico introducida ya esta en uso");
        }else if(error.code == "auth/invalid-password"){
          setErrorMessage("ERROR: La contraseña debe tener una longitud de al menos 6 caracteres");
        }else{
          setErrorMessage("ERROR: Ha ocurrido un error durante el registro, por favor vuelva a intentarlo")
        }
        estado = "failure";
      }
     
    }
    setStatus(estado);
  };

  return (
      <section>
        <div>
          <div>
            <HeaderComponent></HeaderComponent>
            <Card className='mediumSize alignCenter'>
            {/* {errorMessage != "" ? <AlertWithIcon message={errorMessage} status={status}></AlertWithIcon> : null} */}
              <form>
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="name2"
                    value="Tu nombre"
                  />
                </div>
                <TextInput
                  id="name2"
                  placeholder="Ej: Antonio"
                  value={name} 
                  onChange={(e) => setName(e.target.value)}
                  required
                  shadow
                  type="text"
                  className="formInputs"
                  contentEditable
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="apellidos2"
                    value="Apellidos"
                  />
                </div>
                <TextInput
                  id="apellidos2"
                  placeholder="Ej: Rodriguez Gonzalez"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  required
                  shadow
                  className="formInputs"
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="birthdate2"
                    value="Fecha de nacimiento"
                  />
                </div>
                <Datepicker id="datepicker" onSelectedDateChanged={(e) => setBirthdate(e)} language='es-ES' weekStart={2}></Datepicker>
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email-address"  value="Direccion de correo electrónico">
                    
                  </Label>
                </div>
                <TextInput
                  type="email"
                  id="email"
                  label="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  shadow
                  placeholder="Direccion de correo electrónico"
                  className="formInputs"
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="password" value="Contraseña"/>
                    
                </div>
                <TextInput
                  type="password"
                  id="password"
                  label="Create password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  shadow
                  placeholder="Contraseña"
                  className="formInputs"
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="password" value="Repita su contraseña"/>
                    
                </div>
                <TextInput
                  type="password"
                  id="password"
                  label="Create password"
                  value={passwordRepeat}
                  onChange={(e) => setPasswordRepeat(e.target.value)}
                  required
                  shadow
                  placeholder="Contraseña"
                  className="formInputs"
                />
              </div>
              <Button
                className='formBtn marginTop'
                onClick={register}
              >
                Crea tu cuenta
              </Button>

            </form>

            <p className='smallText'>
              ¿Ya tienes una cuenta?{' '}
              <Link to="/"  className='LinkColors'>
                Iniciar sesión
              </Link>
            </p>
          </Card>
          <nav className='divToast' onClick={()=>{setStatus("")}}>
            {/* {status == "success" ? <ToastMSG message={errorMessage}></ToastMSG> : null} */}
            {status == "failure" ? <ToastErrMSG message={errorMessage} count={1} ></ToastErrMSG> : null}
          </nav>
        </div>
      </div>
    </section>
    
  )
}


import React, { useEffect, useState, useCallback, createContext } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Card, Avatar, Dropdown, Button } from 'flowbite-react';
import { useNavigate, redirect, Route, Navigate, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth, db } from "../firebase_setup/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import gato from '../img/gato.png'
import { ActivityDeleteBtn } from './components/DeleteButton';
import ToastMSG from './components/Toast';
import { ToastErrMSG } from './components/Toast';
import HeaderBar from './components/Header';


function DefaultCard(props) {
  const navigate = useNavigate();
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  const isAdmin = currentUser?.Admin ? currentUser.Admin : false;
  let precio = ""
  const onDelete = (childData) => {

    props.changeDeleted(childData)
  }
  if (props.price == 0){
    precio = "Gratis"
  }else {
    precio= props.price+"€"
  }
  return (
    <Card
      imgAlt="Imagen referente a la actividad"
      imgSrc={props.img}
      className='cardSize'
    >
      <div >
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {props.name}
        </h5>
        <p className="font-normal text-gray-700 dark:text-gray-400 cardTextSize">
          {props.description}
        </p>
        <p className="font-normal text-gray-700 dark:text-gray-400 alignLeft">
          {precio}
        </p>
      </div>
      {isAdmin != "" ? <ActivityDeleteBtn changeDeleted={onDelete} id={props.id} horariosActividades={props.horariosActividades}></ActivityDeleteBtn> : null}
    </Card>
  )
}
// eslint-disable-next-line react-hooks/rules-of-hooks


function MainPageComponent() {
  const [activities, setActivities] = useState([]);
  const [activitiesSchedules, setActivitySchedules] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [status, setStatus] = useState("");
  const [toastSuccess, setToastSuccess] = useState("");
  const location = useLocation();


  const navigate = useNavigate();

  const changeDeleted = (childData) => {
    setDeleted(true);
    const reservesUpdated = []
    activities.forEach(activity => {
      if (activity.id != childData) {
        reservesUpdated.push(activity)
      }
    })
    setActivities(reservesUpdated);
  }

  useEffect(() => {


    if (location && location.state && location.state.message !== "" ) {
      setToastSuccess(location.state.message)
      setStatus(location.state.err)

    } 

    const fetchActivities = async () => {
      const response = collection(db, "Activities");
      const q = query(response, where("Blocked", "==", false));
      const data = await getDocs(q)
      const actividades = []
      data.docs.forEach(item => {
        var fData = item.data();
        fData.id = item.id;
        actividades.push(fData);
        setActivities(actividades)
      })
      return activities;
    }
    const fetchActivitiesSchedules = async () => {
      const response = collection(db, "ActivitySchedule");
      const data = await getDocs(response);
      const horarioActividades = [];
      data.docs.forEach(item => {
        var fData = item.data();
        fData.id = item.id;
        horarioActividades.push(fData);
        setActivitySchedules(horarioActividades)
      })
      return activitiesSchedules;
    }
    fetchActivities();
    fetchActivitiesSchedules();
  }, []);
  if (activities[0] && activitiesSchedules[0]) {
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    const isAdmin = currentUser?.Admin ? currentUser.Admin : false;
    return (
      <>
        <Container data={activities}>
          <h1 className='marginTop titleActivities avatarTitle'>
            Actividades
          </h1>
          {isAdmin != "" ? <button onClick={() => navigate("/AddActivity")} className='addBtn'>
            <svg class="w-5 h-5 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 5.757v8.486M5.757 10h8.486M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
            <p>
              Nueva Actividad
            </p>

          </button> : null}
          {isAdmin != "" ? <button onClick={() => navigate("/AddZone")} className='addBtn'>
            <svg class="w-5 h-5 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 5.757v8.486M5.757 10h8.486M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
            <p>
              Nueva Zona
            </p>

          </button> : null}

          {isAdmin != "" ? <button onClick={() => navigate("/AddCalendar")} className='addBtn'>
            <svg class="w-5 h-5 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 5.757v8.486M5.757 10h8.486M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
            <p>
              Nuevo Calendario
            </p>

          </button> : null}

          <Row >
            {activities.map(function (actividad, i) {
              return (
                <Col sm={4} className='marginBot onClicNav' onClick={() => navigate("/details", { state: { activity: actividad } })}>
                  <DefaultCard name={actividad.Name} description={actividad.Description} price={actividad.Price} img={actividad.IMG} id={actividad.id} changeDeleted={changeDeleted} actividad={actividad} horariosActividades={activitiesSchedules}></DefaultCard>
                </Col>
              )
            })}
          </Row>
        </Container>
        {status == "success" ?
          <nav onClick={() => setStatus("")}>
            <ToastMSG message={toastSuccess}></ToastMSG></nav>
          : null}
        {status == "failure" ?
          <nav onClick={() => setStatus("")}>
            <ToastErrMSG message={toastSuccess} count={1}></ToastErrMSG></nav>
          : null
        }
      </>
    )
  } 

}
export default function MainPage() {
  if (sessionStorage.getItem("user")) {
    return (
      <>
        <HeaderBar></HeaderBar>
        <MainPageComponent></MainPageComponent>
      </>
    )
  } else {
    return (
      <Navigate replace to='/' />
    )
  }

}
import React from 'react';

function dayOfWeek(day){
    switch (day) {
        case 1:
             return "Lun"
        case 2:
             return "Mar"
        case 3:
             return "Mie"
        case 4:
             return "Jue"
        case 5:
             return "Vie"
        case 6:
             return "Sab"
        case 0:
             return "Dom"
       
        default:
            break;
    }
}

const Day = ({ day, onDayClick, id, blockDay, daySelected }) => {
    
    return (
     <div className={`daysBox 
     ${id.getDate() === new Date().getDate()  ? "firstDayOfWeek " : ""} 
     ${id.getDate() === daySelected.getDate() ? "daySelected " : ""} 
     ${blockDay ? "bloquedDay" : ""}`

     } 
     id={id} 
     onClick={blockDay ? undefined : onDayClick.bind({id}) }>
        <div className='daysContainer'>
            <div className='day'>
                <div className={`whiteLetter dayOfWeekLetter ${dayOfWeek(day.getDay()) === "Sab" || dayOfWeek(day.getDay()) === "Dom" ? "weekEnd" : ""}`}>{dayOfWeek(day.getDay())}</div>
                <div className={`dayLetter ${dayOfWeek(day.getDay()) === "Sab" || dayOfWeek(day.getDay()) === "Dom" ? "weekEnd" : ""}`}>{day.getDate()}</div>
            </div>          
        </div>
        <div className='yearOfDay whiteLetter'>{day.toLocaleString('default', {month: 'short'})}</div>
     </div>
    )
};

export default Day;
import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Card, Avatar, Dropdown } from 'flowbite-react';
import { useNavigate, Redirect, Route, Navigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth, db } from "../../firebase_setup/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";



function AvatarText() {
  const [users, setUsers] = useState([])
  const navigate = useNavigate();
  function handleLogout() {
    signOut(auth).then(() => {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("userMail");
      sessionStorage.removeItem("currentUser");
      sessionStorage.removeItem("reserveDeletion");
      sessionStorage.removeItem("pendingId");
      navigate("/", { replace: true });
      // console.log("Signed out successfully")
    }).catch((error) => {
    });
  }
  function goToReserves() {
    navigate("/myBookings", { replace: true });
    // console.log("Signed out successfully")
  }
  function goToProfileUpdate(){
    navigate("/profileUpdate",{replace:true});
  }
  useEffect(() => {
    const fetchUsers = async () => {
      const response = collection(db, "Users");
      const q = query(response,where("Email","==",sessionStorage.getItem("userMail")))
      const data = await getDocs(q)
      const usuarios = []
      data.docs.forEach(item => {
        // if(item.data.Email == auth.currentUser.email){
        let itemData = item.data()
        itemData.id = item.id
        usuarios.push(itemData)
        setUsers(usuarios)
        // }
      })
      return users;
    }
    fetchUsers();
  }, []);
  let currentUser;
  users.forEach(user => {
      currentUser = user
  })
  if (currentUser) {
    sessionStorage.setItem("currentUser",JSON.stringify(currentUser));
    return (
      <Dropdown
        className='borderRadiusAvatarSm'
        inline
        label={<Avatar
          rounded
        >
          <div className="space-y-1 font-medium avatarTitle avatarTitleSize">
            <div>
              {currentUser.Email}
            </div>
          </div>
        </Avatar>}
      >
        <Dropdown.Header>
          <span className="block text-sm">
            {currentUser.Name} {currentUser.LastName}
          </span>
          <span className="block truncate text-sm font-medium">
            {currentUser.Email}
          </span>
        </Dropdown.Header>
        <Dropdown.Item onClick={goToProfileUpdate}>
          Configuracion de perfil
        </Dropdown.Item>
        <Dropdown.Item onClick={goToReserves}>
          Mis reservas
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={handleLogout}>
          Cerrar sesion
        </Dropdown.Item>
      </Dropdown>
    )
  }else{
    return (
      <Dropdown
        className='borderRadiusAvatarSm'
        inline
        label={<Avatar
          rounded
        >
          <div className="space-y-1 font-medium avatarTitle avatarTitleSize">
            <div>
              {sessionStorage.getItem("userMail")}
            </div>
          </div>
        </Avatar>}
      >
        <Dropdown.Item onClick={goToProfileUpdate}>
          Configuración de perfil
        </Dropdown.Item>
        <Dropdown.Item onClick={goToReserves}>
          Mis reservas
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={handleLogout}>
          Cerrar sesión
        </Dropdown.Item>
      </Dropdown>
    )
  }

}


export default function HeaderBar() {
  const navigate = useNavigate();

  // declarar los handle dentro del componente o pasar contexto
  function GoToMain() {
    navigate('/main', { replace: true });
    //<>
    //    <Route path="/main"></Route>
    //</>
  }
  return (
    <header className="relative flex flex-wrap items-center justify-between px-2 py-3 mb-3 headerBg borderRadiusSm">
      <h1 className='titleMain' onClick={GoToMain}>Reservas Villagonzalo</h1>
      <div className='smallMargin bg-gray-100 borderRadiusAvatarSm'>
        <AvatarText></AvatarText>
      </div>
    </header>
  )
}
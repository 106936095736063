import React from 'react';
import { useState,useEffect, createContext  } from 'react'
import { Link, useNavigate,Navigate  } from 'react-router-dom'
import { getAuth, createUserWithEmailAndPassword} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, addDoc } from "../firebase_setup/firebase";
import { collection, query, where, getDocs,doc,updateDoc } from "firebase/firestore"; 
import { Button, Card, Checkbox, Label, TextInput, Select    } from 'flowbite-react';
import HeaderBar from './components/Header';
import AlertWithIcon from "./components/alerts"
import ToastMSG from './components/Toast';
import { ToastErrMSG } from './components/Toast';

function AddZoneComponent(){
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    const auth = getAuth();
    const [name, setName] = useState("");
    const [location,setLocation] = useState("");
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState("")
    const navigate = useNavigate();
   
    const clearform = () => {
      setName("")
      setLocation("")
    }
    const AddNewZone = async () => {
      if (name == "" || Location == "" ){
        setMessage("ERROR:Por favor, revise los datos introducidos");
        setStatus("failure")
      }else{
        try {
          await addDoc(collection(db, "Zones"), {
              Location: location,
              Name: name,
          }).then(
              setMessage("Zona creada correctamente"),
              setStatus("success"),
              clearform(),
              navigate('/main',{ state: { message: "Zona creada correctamente",err:"success" } })
          )
      } catch (error) {
          console.log(error)
          setMessage("ERROR: No se ha podido crear la zona")
          clearform()
      }
      }
    

    }
      return(
        <section>
        <div>
          <div>

            <Card className='mediumSize alignCenter'>
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Nueva zona</h5>
              <form>
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="name2"
                    value="Nombre"
                  />
                </div>
                <TextInput
                  id="name2"
                  placeholder="Nombre de la zona"
                  value={name} 
                  onChange={(e) => setName(e.target.value)}
                  shadow
                  type="text"
                  className="formInputs"
                  required
                  contentEditable
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="location"
                    value="Dirección"
                  />
                </div>
                <TextInput
                  id="location"
                  placeholder="Dirección de la zona"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  shadow
                  required
                  className="formInputs"
                />
              </div>
    
              <Button
                className='formBtn marginTop'
                onClick={AddNewZone}
              >
              Guardar Zona
              </Button>
        
            </form>
          </Card>
          <nav className='divToast'>
          <nav className='divToast' onClick={()=>{setStatus("")}}>
              {status == "success" ? <ToastMSG message={message}></ToastMSG> : null}
              </nav>
              <nav className='divToast' onClick={()=>{setStatus("")}}>
              {status == "failure" ? <ToastErrMSG message={message} count={1}></ToastErrMSG> : null}
          </nav>
          </nav>
        </div>
      </div>
    </section>
    )
    }
  
        
    


export default function AddZone() {
    if (sessionStorage.getItem("user")) {
      return (
        <>
          <HeaderBar></HeaderBar>
          <AddZoneComponent></AddZoneComponent>
        </>
      )
    } else {
      return (
        <Navigate replace to='/' />
      )
    }
}
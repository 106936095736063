import React, { useEffect, useState, useCallback, createContext } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Card, Avatar, Dropdown, Button } from 'flowbite-react';
import { useNavigate, redirect, Route, Navigate, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth, db } from "../firebase_setup/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import gato from '../img/gato.png'
import { ActivityDeleteBtn } from './components/DeleteButton';
import ToastMSG from './components/Toast';

import HeaderBar from './components/Header';

function HeaderComponent() {

    const navigate = useNavigate();

    // declarar los handle dentro del componente o pasar contexto
    function BacktoLogin() {
        navigate('/', { replace: true });
    }
    return (
        <>
            <h1 className='titleDefault avatarTitle cursorPointerHead' onClick={BacktoLogin}>Reservas Villagonzalo</h1>
        </>
    )
}
function PrivacyPolicyComponent() {
    return (

        <div className='privacyPolicy'>
            <h1>Política de privacidad</h1>

            <p> 
                El Ayuntamiento de Villagonzalo te informa sobre su Política de Privacidad respecto al tratamiento y protección de los datos de carácter personal de los usuarios y clientes que puedan ser recabados por la navegación o contratación de servicios a través del sitio Web villagonzalo.app.<br></br>
                En este sentido, el Titular garantiza el cumplimiento de la normativa vigente en materia de protección de datos personales, reflejada en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y de Garantía de Derechos Digitales (LOPD GDD). Cumple también con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas (RGPD).
                El uso de sitio Web implica la aceptación de esta Política de Privacidad, así como las condiciones incluidas en el Aviso Legal.
            </p>
            <h2>Identidad del responsable</h2>
            <p>
                <ul className='privacyPolicyList'>
                    <li>•	<b>Titular:</b> Ayuntamiento de Villagonzalo</li>
                    <li>•	<b>NIF/CIF:</b> 243036852</li>
                    <li>•	<b>Domicilio:</b> Plaza de España nº 1 06473 Villagonzalo (Badajoz)</li>
                    <li>•	<b>Correo electrónico:</b> villagonzalo@dip-badajoz.es</li>
                    <li>•	<b>Sitio Web:</b>  villagonzalo.app</li>
                </ul>
            </p>
            <h2>Principios aplicados en el tratamiento de datos</h2>
            <p>
                En el tratamiento de tus datos personales, el Titular aplicará los siguientes principios que se ajustan a las exigencias del nuevo reglamento europeo de protección de datos:<br></br>
                <ul className='privacyPolicyList'>
                    <li>•	<b>Principio de licitud, lealtad y transparencia:</b> El Titular siempre requerirá el consentimiento para el tratamiento de tus datos personales que puede ser para uno o varios fines específicos sobre los que te informará previamente con absoluta transparencia.</li>
                    <li>•	<b>Principio de minimización de datos:</b> El Titular te solicitará solo los datos estrictamente necesarios para el fin o los fines que los solicita.</li>
                    <li>•	<b>Principio de limitación del plazo de conservación:</b> Los datos se mantendrán durante el tiempo estrictamente necesario para el fin o los fines del tratamiento.
                        El Titular te informará del plazo de conservación correspondiente según la finalidad. En el caso de suscripciones, el Titular revisará periódicamente las listas y eliminará aquellos registros inactivos durante un tiempo considerable.</li>
                    <li>•	<b>Principio de integridad y confidencialidad:</b> Tus datos serán tratados de tal manera que su seguridad, confidencialidad e integridad esté garantizada. Debes saber que el Titular toma las precauciones necesarias para evitar el acceso no autorizado o uso indebido de los datos de sus usuarios por parte de terceros.</li>
                </ul>
            </p>
            <h2>Obtención de datos personales</h2>
            <p>
                Para navegar por villagonzalo.app no es necesario que facilites ningún dato personal. Los casos en los que sí proporcionas tus datos personales son los siguientes:
            </p>
            <ul className='privacyPolicyList'>
                <li>•	En el momento de iniciar sesión en la aplicación.</li>
                <li>•	Al realizar una reserva en la aplicación.</li>
            </ul>
            <h2>Tus derechos</h2>
            <p>
                El Titular te informa que sobre tus datos personales tienes derecho a:
            </p>
            <ul className='privacyPolicyList'>
                <li>•	Solicitar el acceso a los datos almacenados.</li>
                <li>•	Solicitar una rectificación o la cancelación.</li>
                <li>•	Solicitar la limitación de su tratamiento.</li>
                <li>•	Oponerte al tratamiento.</li>
                <li>•	Solicitar la portabilidad de tus datos.</li>
            </ul>
            <p>
                El ejercicio de estos derechos es personal y por tanto debe ser ejercido directamente por el interesado, solicitándolo directamente al Titular, lo que significa que cualquier cliente, suscriptor o colaborador que haya facilitado sus datos en algún momento puede dirigirse al Titular y pedir información sobre los datos que tiene almacenados y cómo los ha obtenido, solicitar la rectificación de los mismos, solicitar la portabilidad de sus datos personales, oponerse al tratamiento, limitar su uso o solicitar la cancelación de esos datos en los ficheros del Titular.<br></br>
                Para ejercitar tus derechos de acceso, rectificación, cancelación, portabilidad y oposición tienes que enviar un correo electrónico a villagonzalo@dip-badajoz.es junto con la prueba válida en derecho como una fotocopia del D.N.I. o equivalente.<br></br>
                Tienes derecho a la tutela judicial efectiva y a presentar una reclamación ante la autoridad de control, en este caso, la Agencia Española de Protección de Datos, si consideras que el tratamiento de datos personales que te conciernen infringe el Reglamento.
            </p>
            <h2>Finalidad del tratamiento de datos personales</h2>
            <p>
                Cuando te conectas al sitio Web para mandar un correo al Titular, te suscribes a su boletín o realizas una contratación, estás facilitando información de carácter personal de la que el responsable es el Titular. Esta información puede incluir datos de carácter personal como pueden ser tu dirección IP, nombre y apellidos, dirección física, dirección de correo electrónico, número de teléfono, y otra información. Al facilitar esta información, das tu consentimiento para que tu información sea recopilada, utilizada, gestionada y almacenada por villagonzalo.app, sólo como se describe en el Aviso Legal y en la presente Política de Privacidad.<br></br>
                Los datos personales y la finalidad del tratamiento por parte del Titular son diferente según el sistema de captura de información:<br></br>
                Existen otras finalidades por las que el Titular trata tus datos personales:
            </p>
            <ul className='privacyPolicyList'>
                <li>•	Para garantizar el cumplimiento de las condiciones recogidas en el Aviso Legal y en la ley aplicable. Esto puede incluir el desarrollo de herramientas y algoritmos que ayuden a este sitio Web a garantizar la confidencialidad de los datos personales que recoge.</li>
                <li>•	Para apoyar y mejorar los servicios que ofrece este sitio Web.</li>
                <li>•	Para analizar la navegación. El Titular recoge otros datos no identificativos que se obtienen mediante el uso de cookies que se descargan en tu ordenador cuando navegas por el sitio Web cuyas características y finalidad están detalladas en la Política de Cookies.</li>
                <li>•	Para gestionar las redes sociales. el Titular tiene presencia en redes sociales. Si te haces seguidor en las redes sociales del Titular el tratamiento de los datos personales se regirá por este apartado, así como por aquellas condiciones de uso, políticas de privacidad y normativas de acceso que pertenezcan a la red social que proceda en cada caso y que has aceptado previamente.</li>
            </ul>
            <p>
                Puedes consultar las políticas de privacidad de las principales redes sociales en estos enlaces:
            </p>
            <ul className='privacyPolicyList'>
                <li>•	Facebook</li>
                <li>•	Gmail</li>
            </ul>
            <p>
                El Titular tratará tus datos personales con la finalidad de administrar correctamente su presencia en la red social, informarte de sus actividades, productos o servicios, así como para cualquier otra finalidad que las normativas de las redes sociales permitan.<br></br>
                En ningún caso el Titular utilizará los perfiles de seguidores en redes sociales para enviar publicidad de manera individual.
            </p>
            <h2>Seguridad de los datos personales</h2>
            <p>
                Para proteger tus datos personales, el Titular toma todas las precauciones razonables y sigue las mejores prácticas de la industria para evitar su pérdida, mal uso, acceso indebido, divulgación, alteración o destrucción de estos.<br></br>
                El sitio Web está alojado en Google Cloud. La seguridad de tus datos está garantizada, ya que toman todas las medidas de seguridad necesarias para ello. Puedes consultar su política de privacidad para tener más información.<br></br>
            </p>
            <h2>Contenido de otros sitios web</h2>
            <p>
                Las páginas de este sitio Web pueden incluir contenido incrustado (por ejemplo, vídeos, imágenes, artículos, etc.). El contenido incrustado de otra web se comporta exactamente de la misma manera que si hubieras visitado la otra web.<br></br>
                Estos sitios Web pueden recopilar datos sobre ti, utilizar cookies, incrustar un código de seguimiento adicional de terceros, y supervisar tu interacción usando este código.
            </p>
            <h2>Política de Cookies</h2>
            <p>
                Para que este sitio Web funcione correctamente necesita utilizar cookies, que es una información que se almacena en tu navegador web.<br></br>
                En la página Política de Cookies puedes consultar toda la información relativa a la política de recogida, la finalidad y el tratamiento de las cookies.
            </p>
            <h2>Legitimación para el tratamiento de datos</h2>
            <p>
                La base legal para el tratamiento de tus datos es: el consentimiento.<br></br>
                Para contactar con el Titular, suscribirte a un boletín o realizar comentarios en este sitio Web tienes que aceptar la presente Política de Privacidad.
            </p>
            <h2>Categorías de datos personales</h2>
            <p>
                Las categorías de datos personales que trata el Titular son:
                <ul className='privacyPolicyList'>
                    <li>•	Datos identificativos.</li>
                </ul>
            </p>
            <h2>Conservación de datos personales</h2>
            <p>Los datos personales que proporciones al Titular se conservarán hasta que solicites su supresión.</p>
            <h2>Destinatarios de datos personales</h2>
            <p>
                <ul className='privacyPolicyList'>
                    <li><b>•	Google Analytics </b>es un servicio de analítica web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”). Encontrarás más información en: https://analytics.google.com
                        Google Analytics utiliza “cookies”, que son archivos de texto ubicados en tu ordenador, para ayudar al Titular a analizar el uso que hacen los usuarios del sitio Web. La información que genera la cookie acerca del uso del sitio Web (incluyendo tu dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos.
                    </li>
                </ul>
                También puedes ver una lista de los tipos de cookies que utiliza Google y sus colaboradores y toda la información relativa al uso que hacen de cookies publicitarias.
            </p>
            <h2>Navegación Web</h2>
            <p>
            Al navegar por villagonzalo.app se pueden recoger datos no identificativos, que pueden incluir, la dirección IP, geolocalización, un registro de cómo se utilizan los servicios y sitios, hábitos de navegación y otros datos que no pueden ser utilizados para identificarte. <br></br>
            El sitio Web utiliza los siguientes servicios de análisis de terceros:
                <ul className='privacyPolicyList'>
                    <li>•	Google Analytics</li>
                </ul>
            El Titular utiliza la información obtenida para obtener datos estadísticos, analizar tendencias, administrar el sitio, estudiar patrones de navegación y para recopilar información demográfica.
            </p>
            <h2>Exactitud y veracidad de los datos personales</h2>
            <p>
            Te comprometes a que los datos facilitados al Titular sean correctos, completos, exactos y vigentes, así como a mantenerlos debidamente actualizados.<br></br>
            Como Usuario del sitio Web eres el único responsable de la veracidad y corrección de los datos que remitas al sitio exonerando a el Titular de cualquier responsabilidad al respecto.
            </p>
            <h2>Aceptación y consentimiento</h2>
            <p>
            Como Usuario del sitio Web declaras haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptas y consientes el tratamiento de estos por parte del Titular en la forma y para las finalidades indicadas en esta Política de Privacidad.
            </p>
            <h2>Revocabilidad</h2>
            <p>
            Para ejercitar tus derechos de acceso, rectificación, cancelación, portabilidad y oposición tienes que enviar un correo electrónico a villagonzalo@dip-badajoz.es junto con la prueba válida en derecho como una fotocopia del D.N.I. o equivalente.<br></br>
            El ejercicio de tus derechos no incluye ningún dato que el Titular esté obligado a conservar con fines administrativos, legales o de seguridad.
            </p>
            <h2>Cambios en la Política de Privacidad</h2>
            <p>
            El Titular se reserva el derecho a modificar la presente Política de Privacidad para adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas de la industria.<br></br>
            Estas políticas estarán vigentes hasta que sean modificadas por otras debidamente publicadas.
            </p>
        </div>
    )


}

export default function PrivacyPolicy() {
    if (sessionStorage.getItem("user")) {
        return (
            <>
                <HeaderBar></HeaderBar>
                <PrivacyPolicyComponent></PrivacyPolicyComponent>
            </>
        )
    } else {
        return (
            <>
                <HeaderComponent></HeaderComponent>
                <PrivacyPolicyComponent></PrivacyPolicyComponent>
            </>
        )
    }
}
import React, { useEffect, useState, useCallback } from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { auth, db, doc, query, database } from "../firebase_setup/firebase";
import HeaderBar from './components/Header';
import HorizontalCalendar from './components/CalendarComponents/HorizontalCalendar';
import { getFirestore, collection, where, getDocs, Firestore, and, QuerySnapshot, onSnapshot } from 'firebase/firestore';
import { Container, Row, Col } from 'react-grid-system';
export default function ActivityDetails() {
  const location = useLocation();
  const activity = location.state.activity
  const selectedReserve = location.state.reserve
  const [reservations, setReservations] = useState([]);
  const [pendingReservations, setPendingReservations] = useState([])

  useEffect(() => {
    const getSchedule = async () => {
      const reservationSch = await query(collection(db, 'Reservations'))
      const pendingReservationsQuery = await query(collection(db, 'PendingReservations'), where("PendingPayment", "==", true), where('ReservationDay','>=',new Date(new Date().getTime()-(60*60000))))
        onSnapshot(reservationSch, (querySnapshot) => {
        setReservations(querySnapshot.docs.map((r) => {
          var d = r.data()
          d.id = r.id;
          return d
        }))
      })
      onSnapshot(pendingReservationsQuery, (querySnapshot) =>{
        setPendingReservations(querySnapshot.docs.map((r) => {
            var d = r.data()
            d.id = r.id;
            return d
          

        }))
      })
      return
    }

    getSchedule();
  }, []);


  if (sessionStorage.getItem("user")) {
    return (
      <div>
        <HeaderBar />

            <div className='headerIMG' style={{
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 80%, #f6f9fe 100%), url(${activity.IMG})`,
            }} >
              <div>
                <h1 className='headerColorTitle'>{activity.Name}</h1>
                <h3 className='headerColorTitle'>{activity.Description}</h3>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: 'center', margin: "auto" }}>
              <HorizontalCalendar rs={reservations} pendingReservations={pendingReservations} activityRef={activity} selectedReserve={selectedReserve}/>
            </div>
          
        
      </div>

    )
  } else {
    return (
      <Navigate replace to='/' />
    )
  }

}
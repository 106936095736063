'use client';

import './App.css';
import React, {createContext} from 'react';
import { Button, Card, Checkbox, Label, TextInput } from 'flowbite-react';
import { BrowserRouter, Routes, Route, Link  } from "react-router-dom";
import  MainPage from "./pages/MainPage"
import Login from "./pages/Login"
import SignIn from "./pages/SignIn"
import ActivityDetails from './pages/ActivityDetails';
import MyReserves from './pages/MyReserves';
import ProfileUpdate from './pages/ProfileUpdate';
import AddActivity from './pages/AddActivity';
import AddZone from './pages/AddZone';
import AddCalendar from './pages/AddCalendar';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PaymentConfirmed from './pages/PaymentConfirmed';
import PaymentDenied from './pages/PaymentDenied';
import About from './pages/about';
import junta from './img/pastilla verde.jpg'



function FooterComponent(){
return(
  <footer className="headerBg rounded-lg shadow m-4 footerPos" >
    <div className="w-full mx-auto max-w-screen-xl md:flex md:items-center md:justify-between">
      <span className="text-sm text-white-500 sm:text-center dark:text-gray-400 footerSpan">© 2023 <a href="https://www.resultoconsultoria.com/" className="LinkColors">Resulto Consultoria</a>. Todos los derechos reservados.
    </span>
        <div>
      <img src={junta} alt="junta de extremadura" className="imgJuntaFooter"></img>
    </div>
    <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0 footerUl">
        <li>
            <a href="/Privacy-policy" className="mr-4 LinkColors md:mr-6">Politica de privacidad</a>
            <a href="/About" className="mr-4 LinkColors md:mr-6">Acerca de</a>
        </li>
    </ul>
    </div>

</footer>
)
}

export default function MainComponent(){
  return(
    <>

    {/* <header>
    <meta Content-Security-Policy-Report-Only: 
    script-src= "https://accounts.google.com/gsi/client;" 
    frame-src= "https://accounts.google.com/gsi/;" 
    connect-src= "https://accounts.google.com/gsi/;" 
    />
    </header> */}
    <body>
      <><BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/main" element={<MainPage />} />
        <Route path="/signIn" element={<SignIn />} />
        <Route path="/details" element={<ActivityDetails/>} />
        <Route path="/myBookings" element={<MyReserves/>} />
        <Route path='/profileUpdate' element={<ProfileUpdate/>}/>
        <Route path='/AddActivity' element={<AddActivity/>}/>
        <Route path='/AddZone' element={<AddZone/>}/>
        <Route path='/AddCalendar' element={<AddCalendar/>}/>
        <Route path='/Privacy-policy' element={<PrivacyPolicy/>}/>
        <Route path='/PaymentConfirmed' element={<PaymentConfirmed/>}/>
        <Route path='/PaymentDenied' element={<PaymentDenied/>}/>
        <Route path='/About' element={<About/>}/>
      </Routes>
    </BrowserRouter></>
    </body>
    <footer>
    <FooterComponent></FooterComponent>
    </footer>
    </>

  )

}



import React, { Component, useState, useEffect, ReactDOM } from 'react'
import Day from './Day'
import TimeCalendar from './TimeCalendar';
import { Container, Row, Col } from 'react-grid-system';
import { collection, getDocs, doc } from 'firebase/firestore';
import { auth, db, query, database } from "../../../firebase_setup/firebase";
import { Dropdown } from 'flowbite-react';

var daySelected = new Date();
var calendarsRef = React.createRef();
var currentCalendarRef = React.createRef();
var selectedActivitySchedule = React.createRef();
var zonesRef = React.createRef();
var divCalendarHorizontal = React.createRef();
var reservations = React.createRef();
var pendingReservations = React.createRef();
var selectedZoneRef = React.createRef();
var activityRef = React.createRef();
var calendarConnections = React.createRef();
var activeDay = React.createRef();
activeDay.current = false

const HorizontalCalendar = (Schedules) => {
    const [currentM, setCurrentMonth] = useState(new Date());
    const [timeCalendar, setTimeCalendar] = useState();
    const [zones, setZones] = useState([])
    const [activitySchedule, setActivitySchedule] = useState([]);
    const [calendars, setCalendars] = useState([]);
    const [currentCalendar, setCurrentCalendar] = useState(null);
    const [selectedZone, setSeletedZone] = useState("");
    const [horizontalCalendar, setHorizontalCalendar] = useState([]);

    reservations.current = Schedules.rs
    pendingReservations.current = Schedules.pendingReservations


    activityRef.current = Schedules.activityRef
    useEffect(() => {
        daySelected = new Date();   
        activeDay.current = false
    }, [])

    useEffect(() => {
        const getSchedule = async () => {
            selectedZoneRef.current = undefined
            const zonesData = await getDocs(collection(db, 'Zones'))
            const actShedule = await getDocs(collection(db, 'ActivitySchedule'))
            const calendarSch = await getDocs(collection(db, 'Calendars'))

            //Zones
            if (zonesData != undefined) {
                zonesRef.current = zonesData.docs.map((z) => {
                    var d = z.data();
                    d.id = z.id;
                    return d;
                })
                zonesRef.current.sort((a, b) => {
                    if (a.Name < b.Name) {
                        return -1;
                    }
                    if (a.Name > b.Name) {
                        return 1;
                    }
                    return 0;
                });
            }

            //Relation Zone, Activity and Calendar          
            if (actShedule != undefined) {
                var relationCalendar = actShedule.docs.map((z) => {
                    var d = z.data()
                    // d.Activity = doc(db, "Activities", d.Activity.id);
                    // d.Zone = doc(db, "Zone", d.Zone.id);
                    return d
                })
                if (zonesRef.current != undefined && zonesRef.current.length > 0) {
                    var zonesFiltered = zonesRef.current.filter(z =>
                        relationCalendar.find(c =>
                            c.Zone.id != undefined &&
                            c.Activity.id != undefined &&
                            c.Zone.id.includes(z.id) &&
                            c.Activity.id.includes(activityRef.current.id)
                        ) != null
                    )
                    zonesRef.current = zonesFiltered
                }
                if (zonesData != undefined) {
                    //Set Zones
                    setZones(zonesRef.current)
                    if(selectedZoneRef.current != undefined){                    
                        setSeletedZone(selectedZoneRef.current)
                    }else{
                        setSeletedZone(zonesRef.current[0])
                        selectedZoneRef.current = zonesRef.current[0]
                    }
                }
                calendarConnections.current = relationCalendar
                if (calendarSch != undefined)
                    calendarsRef.current = calendarSch.docs.map((z) => z.data())
                setCalendars(calendarsRef.current)
                changeCalendar(calendarsRef.current)

                //Set Relations
                setActivitySchedule(relationCalendar)

            }

            if (Schedules.selectedReserve != undefined &&
                zonesRef.current != undefined &&
                zonesRef.current.length > 0) {     
                setDayToReservedDay(Schedules.selectedReserve)
                setHorizontalCalendar(renderCalendar())                
            }else{                
                setTimeComponent()    
                setHorizontalCalendar(renderCalendar())
            } 
           
        }
        

        getSchedule()

    }, [Schedules]);
    
    const setDayToReservedDay = (reservation) => {
        if (zonesRef.current.find(z => z.id == reservation.Zone.id))
            selectedZoneRef.current = zonesRef.current.find(z => z.id == reservation.Zone.id)
            setSeletedZone(selectedZoneRef.current)
            daySelected = getDateFromTimeStamp(reservation.StartDay.seconds, reservation.StartDay.nanoseconds)            
            setTimeComponent()
    }


    const changeCalendar = (calendars) => {
        var date = new Date();
        date.setHours(8); date.setMinutes(0); date.setSeconds(0); date.setMilliseconds(0)

        selectedActivitySchedule.current = calendarConnections.current.find(c =>
            c.Activity.id != undefined &&
            c.Zone.id != undefined &&
            c.Activity.id == activityRef.current.id &&
            c.Zone.id == selectedZoneRef.current.id &&
            date.getTime() >= getDateFromTimeStamp(c.StartDate.seconds, c.StartDate.nanoseconds).getTime() &&
            date.getTime() <= getDateFromTimeStamp(c.EndDate.seconds, c.EndDate.nanoseconds).getTime()
        )
        if (calendars !== null && selectedActivitySchedule.current != undefined) {
            currentCalendarRef.current = calendars.find(c => c.CalendarID == selectedActivitySchedule.current.Calendar)
            setCurrentCalendar(currentCalendarRef.current)
        } else {
            currentCalendarRef.current = null
            setCurrentCalendar(null)
        }
    }

    const countOfDays = (count) => {
        const years = currentM.getFullYear();
        const month = currentM.getMonth();
        const currentDay = currentM.getDate();
        return new Date(years, month, currentDay + count)
    };

    const handleDayClick = (event) => {
        let selectedDay = document.getElementsByClassName("daySelected");
        if (selectedDay.item(0))
            selectedDay.item(0).classList.remove("daySelected")

        event.currentTarget.className += " daySelected"
        if (event != null) {
            daySelected = new Date(event.currentTarget.id)
        }
        setTimeComponent();
    }

    const renderCalendar = () => {
        const days = [];        
        
        for (let i = 0; i <= 29; i++) {
            var id = countOfDays(i);
            var blocked = checkBloquedDays(id)
            if(blocked && !activeDay.current){
                daySelected.setDate(daySelected.getDate() + 1)
            }else{
                activeDay.current = true
            }
            days.push(<Day
                key={`day-${countOfDays(i).getDate()}`}
                day={countOfDays(i)}
                onDayClick={handleDayClick}
                blockDay={blocked}
                daySelected={daySelected}
                id={id} />)
        }

        return days;
    };

    function checkBloquedDays(date) {
        var bloquedDay = false
        var selectedCalendar = null

        if (currentCalendarRef.current != null) {
            selectedCalendar = currentCalendarRef.current
        } else {
            selectedCalendar = calendarsRef.current[0]
        }
        if (selectedCalendar != undefined &&
            selectedCalendar.UnabledDays != undefined) {

            if (selectedCalendar.UnabledDays.length > 0) {
                selectedCalendar.UnabledDays.forEach(element => {
                    var bloquedDate = getDateFromTimeStamp(element.Date.seconds, element.Date.nanoseconds)
                    bloquedDate.setHours(0)
                    bloquedDate.setMinutes(0)
                    bloquedDate.setSeconds(0)
                    bloquedDate.setMilliseconds(0)

                    var currentDate = new Date(date)
                    currentDate.setHours(0)
                    currentDate.setMinutes(0)
                    currentDate.setSeconds(0)
                    currentDate.setMilliseconds(0)

                    if (bloquedDate.getTime() == currentDate.getTime()) {
                        bloquedDay = true
                        return
                    }
                });
            }
        }
        if (selectedCalendar != undefined && !bloquedDay) {
            switch (date.getDay()) {
                case 1:
                    bloquedDay = !selectedCalendar.WeekDays.L
                    break
                case 2:
                    bloquedDay = !selectedCalendar.WeekDays.M
                    break
                case 3:
                    bloquedDay = !selectedCalendar.WeekDays.X
                    break
                case 4:
                    bloquedDay = !selectedCalendar.WeekDays.J
                    break
                case 5:
                    bloquedDay = !selectedCalendar.WeekDays.V
                    break
                case 6:
                    bloquedDay = !selectedCalendar.WeekDays.S
                    break
                case 0:
                    bloquedDay = !selectedCalendar.WeekDays.D
                    break

                default:
                    break;
            }

        }
        return bloquedDay
    }


    function getDateFromTimeStamp(seconds, nanoseconds) {
        return new Date((seconds * 1000) + nanoseconds / 1000000)
    }

    function back() {
        divCalendarHorizontal.current.scrollLeft -= 400
    }
    function forward() {
        divCalendarHorizontal.current.scrollLeft += 400
    }
    function zoneChange(zone) {
        selectedZoneRef.current = zone
        changeCalendar(calendars)
        setTimeComponent()

    }
    function setTimeComponent() {
        var zR = ""
        if (selectedZoneRef.current != undefined)
            zR = selectedZoneRef.current
        if (currentCalendarRef.current != null && currentCalendarRef.current != undefined) {
            setTimeCalendar(<TimeCalendar
                selectedDate={daySelected.getTime()}
                calendar={currentCalendarRef.current}
                rs={reservations.current}
                pendingReservations = {pendingReservations.current}
                zoneRef={zR}
                activityRef={activityRef.current}
                refreshCalendar={setTimeComponent}
            />)
        } else {
            setTimeCalendar()
        }
    }

    return (
        <div className='fullWidth'>
            <Container>
                <Row className='horizontalCalendarRow alignCenter' >
                <div>Día seleccionado: {daySelected.toLocaleDateString()}</div>

                    <div className='row'>
                        <Col sm={12} className='horizontalCalendarCol'>
                            <button className='calendarButton' onClick={back}>&lt;</button>
                            <div ref={divCalendarHorizontal} className='calendarHorizontal'>
                                <div className='daysHorizontal' tabIndex={0}>                                    
                                    {horizontalCalendar}
                                </div>
                            </div>
                            <button className='calendarButton' onClick={forward}>&gt;</button>

                        </Col>
                    </div>
                </Row>
                <Row>
                    <Col sm={6}>
                        <div className='titleActivities avatarTitle activityDetailsLabel'>
                            <label>Horario:</label>
                        </div>
                        {/*Pinta los componentes */}
                        {timeCalendar}
                    </Col>
                    <Col sm={6}>
                        <label className='titleActivities avatarTitle centerWithPadding'>Zonas:</label>
                        <div className='center centerWithPadding'>
                            {selectedZone != undefined ?
                                <Dropdown label={selectedZone.Name} size="md">
                                    {zones.map((zone) => (
                                        <Dropdown.Item onClick={() => {
                                            setSeletedZone(zone)
                                            selectedZoneRef.current = zone
                                            zoneChange(zone)
                                        }}>
                                            {zone.Name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown>
                                : ""}
                        </div>


                    </Col>

                </Row>

            </Container>
        </div>
    );
}

export default HorizontalCalendar